import $ from 'jquery';
export default class RcaStoreForm {

    constructor(oeeDatumId, logId = null) {
        this.$form = $('#rca-form');
        this.logId = logId;

        const protocol = window.location.protocol;
        const hostname = window.location.hostname;

        this.logApi = `${protocol}//${hostname}/api/oee/${oeeDatumId}/logs`;

        this.registerEvents();
    }
     
    async loadLogs(logSelect, logType) {
        let $logSelect = $(logSelect);

        await $.get(`${this.logApi}/${logType}`).then(logs => {
            this.clearLogs(logSelect);

            for (var log of logs)
            {
                let selected = this.logId == log.id ? ' selected' : '';
                let option = `<option value="${log.id}"${selected}>${log.label}</option>`;
                $logSelect.append(option);
            }
        });
    }

    clearLogs(logSelect) {
        $(logSelect).empty();
    }

    registerEvents() {
        // change: component        
        this.$form.on('change', 'select[name="log_type"]', e => {
            let logSelect = $('select[name="log_id"]');

            this.loadLogs(logSelect, e.target.value);
        });

        // ready: form: if a log type is selected, load logs
        this.$form.ready(() => {
            this.triggerLogType();
        });
        
    }

    triggerLogType() {
        let $logTypeSelect = this.$form.find('select[name="log_type"]');
        
        $logTypeSelect.trigger('change');
    }
}
