export const pluginLegendPadding = {
    
    beforeInit(chart) {

        const originalFit = chart.legend.fit;
        
        chart.legend.fit = function fit() {

            originalFit.bind(chart.legend)();

            this.height += 20;
            
        }

    }

};