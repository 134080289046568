import $ from 'jquery';
export default class TrHighlight {
    constructor(selector) {
        let tableRows = $(selector).find('tr:not(.tablesorter-headerRow):not(.no-highlight)');

        tableRows.on('click', function() {
            if($(this).hasClass('highlighted')) {
                $(this).removeClass('highlighted');
            }else {
                $(this).addClass('highlighted');
            }
        });
    }
}

