import $ from 'jquery';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { colorConvert } from "../../functions/colorConvert.js";

Chart.register(annotationPlugin);

Chart.defaults.color = '#55688a';
Chart.defaults.font.size = 14;
Chart.defaults.font.family = 'Roboto Condensed';

Chart.defaults.plugins.legend.display = false;
Chart.defaults.plugins.legend.labels.boxWidth = 11;
Chart.defaults.plugins.legend.labels.boxHeight = 11;
Chart.defaults.plugins.legend.labels.padding = 25;

Chart.defaults.plugins.title.fontSize = 20;

Chart.defaults.plugins.tooltip.backgroundColor = '#3d4a5e';
Chart.defaults.plugins.tooltip.padding = 12;
Chart.defaults.plugins.tooltip.caretSize = 7;

Chart.defaults.elements.line.tension = 0.5;
Chart.defaults.elements.line.borderWidth = 2;
Chart.defaults.elements.point.borderWidth = 2;
Chart.defaults.elements.point.radius = 4;
Chart.defaults.elements.point.hoverRadius = 6;

Chart.defaults.datasets.bar.barThickness = 20;
Chart.defaults.datasets.bar.inflateAmount = 0;


/*---  global line options  ------------------------------------------------------*/

var lineOptions = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: true,
            labels: {
                padding: 20,
                usePointStyle: true,
                generateLabels: function(chart) {
                    var datasets = chart.data.datasets;
                    var fontColor = new colorConvert(chart.options.color);

                    return datasets.map(function(dataset, index) {
                        var isHidden = !chart.isDatasetVisible(index);

                        var borderColor = new colorConvert(dataset.borderColor);
                        var backgroundColor = new colorConvert(dataset.backgroundColor);

                        var labelStyles = {
                            datasetIndex: index,
                            text: dataset.label,
                            pointStyle: 'circle',
                            lineWidth: 1,
                            borderRadius: 2,

                            strokeStyle: isHidden ? borderColor.toRGBA(.6) : borderColor.toRGBA(1),
                            fillStyle: isHidden ? backgroundColor.toRGBA(.3) : backgroundColor.toRGBA(.6),
                            fontColor: isHidden ? fontColor.toRGBA(.6) : fontColor.toRGBA(1),
                        };

                        return labelStyles;
                    }, this);
                },
            },
        },
        tooltip: {
            titleMarginBottom: 8,
            displayColors: false,
        },
    },
    layout: {
        padding: {
            top: 10,
            right: 20,
            left: 20,
            bottom: 20,
        },
    },
    scales: {
        x: {
            grid: {
                drawTicks: false,
            },
            ticks: {
                padding: 10,
            },
        },
        y: {
            grid: {
                drawTicks: false,
            },
            ticks: {
                padding: 10,
            },
        }
    },

};


/*  FILLED LINE OPTIONS
-------------------------------------------- */

var filledLineOptions = $.extend(true, {}, lineOptions);
filledLineOptions.plugins.legend.display = false;
filledLineOptions.layout.padding = 20;


/*---  global donut options  ------------------------------------------------------*/


var donutOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1,
    cutout: '75%',
    layout: {
        padding: 20,
    },
    plugins: {
        tooltip: {
            displayColors: false,
            callbacks: {
                title: function(ctx) {
                    return '';
                },
                label: function(ctx) {
                    return ctx.label + '   ' + ctx.dataset.data[ctx.dataIndex] + '%';
                }
            }
        }
    }
}


/*---  global bar options  ------------------------------------------------------*/


var barOptions = {
    maintainAspectRatio: false,
    layout: {
        padding: {
            top: 20,
            bottom: 20,
            left: 30,
            right: 40,
        },
    },
    plugins: {
        tooltip: {
            displayColors: false,
            mode: 'nearest'
        },
    },
    scales: {
        x: {
            beginAtZero: true,
            grid: {
                display: false,
                drawTicks: false,
            },
            ticks: {
                padding: 15,
            },
        },
        y: {
            grid: {
                drawTicks: false
            },
            ticks: {
                padding: 10,
                beginAtZero: true,
            },
        }
    },
};

let horzOptions = {
    indexAxis: 'y',
    scales: {
        x: {
            grid: {
                display: true,
                drawTicks: false
            },
            ticks: {
                padding: 10
            },
        },
        y: {
            grid: {
                display: false
            }
        }
    }
}

let horzBarOptions = $.extend(true, {}, barOptions, horzOptions);


/*---  global bar options (stacked)  ------------------------------------------------------*/


let stacked = {
    scales: {
        x: {
            stacked: true
        },
        y: {
            stacked: true
        }
    }
};

let stackedBarOptions = $.extend(true, {}, barOptions, stacked);
let horzStackedBarOptions = $.extend(true, {}, stackedBarOptions, horzOptions);


/*---  global bar options (legend)  ------------------------------------------------------*/


let barOptionsWithLegend = $.extend(true, {}, {
    animation: false,
    plugins: {
        legend: {
            display: true,
            bottomPadding: 15,
            labels: {
                usePointStyle: true,
                generateLabels: function(chart) {
                    var datasets = chart.data.datasets;
                    var fontColor = new colorConvert(chart.options.color);

                    return datasets.map(function(dataset, index) {
                        var isHidden = !chart.isDatasetVisible(index);

                        var borderColor = new colorConvert(dataset.backgroundColor);
                        var backgroundColor = new colorConvert(dataset.backgroundColor);

                        var labelStyles = {
                            datasetIndex: index,
                            text: dataset.label,
                            pointStyle: 'circle',
                            lineWidth: 2,

                            strokeStyle: isHidden ? borderColor.toRGBA(.6) : borderColor.toRGBA(1),
                            fillStyle: isHidden ? backgroundColor.toRGBA(.3) : backgroundColor.toRGBA(.6),
                            fontColor: isHidden ? fontColor.toRGBA(.6) : fontColor.toRGBA(1),
                        };

                        return labelStyles;
                    }, this);
                },
            },
            onClick: function(e, legendItem) {
                var index = legendItem.datasetIndex;
                var ci = this.chart;
                var meta = ci.getDatasetMeta(index);

                for (var dataset in ci.data.datasets) {
                    ci.data.datasets[dataset].hidden = true;
                    ci.getDatasetMeta(dataset).hidden = true;
                }

                // See controller.isDatasetVisible comment
                ci.data.datasets[index].hidden = false;
                meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;

                // set labels
                ci.data.labels = ci.data.datasets[index].labels;

                // We hid a dataset ... rerender the chart
                ci.update();
            },
        }
    }
}, barOptions);

let horzBarOptionsWithLegend = $.extend(true, {}, barOptionsWithLegend, horzOptions);


/*---  global bar options (multiple datasets + legend)  ------------------------------------------------------*/


let multiBarOptionsWithLegend = $.extend(true, {}, barOptionsWithLegend);
multiBarOptionsWithLegend.plugins.legend.labels = {
    usePointStyle: true,
    generateLabels: function(chart) {
        var datasets = chart.data.data;
        var fontColor = new colorConvert(chart.options.color);

        if (!datasets)
        {
            return datasets;
        }

        return datasets.map(function(dataset, index) {

            var isHidden = dataset.hidden;
            var borderColor = new colorConvert(dataset.backgroundColor);
            var backgroundColor = new colorConvert(dataset.backgroundColor);
            var labelStyles = {
                datasetIndex: index,
                text: dataset.label,
                pointStyle: 'circle',
                lineWidth: 2,

                strokeStyle: isHidden ? borderColor.toRGBA(.6) : borderColor.toRGBA(1),
                fillStyle: isHidden ? backgroundColor.toRGBA(.3) : backgroundColor.toRGBA(.6),
                fontColor: isHidden ? fontColor.toRGBA(.6) : fontColor.toRGBA(1),
            }

            return labelStyles;

        }, this);
    }
};
multiBarOptionsWithLegend.plugins.legend.onClick = function(e, legendItem) {
    var index = legendItem.datasetIndex;
    var ci = this.chart;

    for (var legendIndex in ci.data.data) {
        // show dataset legend item
        ci.data.data[legendIndex].hidden = true;
    }

    for (var dataset in ci.data.data) {
        // change data for new data
        ci.data.datasets[dataset] = ci.data.data[dataset];

        // chnage links for new data
        ci.data.datasets[dataset].links = ci.data.data[index].links;

        // change labels for dataset
        ci.data.labels = ci.data.data[index].labels;
    }

    // hide all dataset legend items
    ci.data.data[index].hidden = false;

    // We hid a dataset ... rerender the chart
    ci.update();
};

let horzMultiBarOptionsWithLegend = $.extend(true, {}, multiBarOptionsWithLegend, horzOptions);


/*---  global bar options (multiple datasets + legend + stacked)  ------------------------------------------------------*/


let stackedMultiBarOptionsWithLegend = $.extend(true, {}, multiBarOptionsWithLegend, stacked);
stackedMultiBarOptionsWithLegend.plugins.legend.onClick = function(e, legendItem) {
    var index = legendItem.datasetIndex;
    var ci = this.chart;

    for (var legendIndex in ci.data.data) {
        // show dataset legend item
        ci.data.data[legendIndex].hidden = true;
    }

    for (var dataset in ci.data.datasets) {
        // change data for new data
        ci.data.datasets[dataset].data = ci.data.data[index].data[dataset];

        // chnage links for new data
        ci.data.datasets[dataset].links = ci.data.data[index].links;

        // change labels for dataset
        ci.data.labels = ci.data.data[index].labels;
    }

    // hide all dataset legend items
    ci.data.data[index].hidden = false;

    // We hid a dataset ... rerender the chart
    ci.update();
};

let horzStackedMultiBarOptionsWithLegend = $.extend(true, {}, stackedMultiBarOptionsWithLegend, horzOptions);

Chart.prototype.loadData = function (chartData, target = null) {

    // assign labels
    this.data.labels = chartData.labels;

    // if basic bar chart
    if (chartData.values) {
        // assign values
        this.data.datasets[0].data = chartData.values;

        // if chart is a bar/horizontal bar then push colours
        // if (['bar', 'horizontalBar'].indexOf(this.config.type) != -1)
        if (this.config.type === 'bar') {
            // get colours
            let backgroundColor = colours['blue'][0];
            let hoverBackgroundColor = colours['blue'][1];

            let optionalColour = chartData.component ? chartData.component : chartData.colour;

            // if oee component exists, use corresponding colour, else use the standard blue
            if (optionalColour) {
                backgroundColor = colours[optionalColour][0];
                hoverBackgroundColor = colours[optionalColour][1];
            }

            // apply colours
            this.data.datasets[0].backgroundColor = new Array(chartData.values.length).fill(backgroundColor);
            this.data.datasets[0].hoverBackgroundColor = new Array(chartData.values.length).fill(hoverBackgroundColor);

            // apply data links
            if (chartData.links && chartData.links.length > 0) {
                this.data.datasets[0].links = chartData.links;
            }
        }
    }

    // if bar chart has additional data i.e. stacked
    else {
        // if chart is a bar/horizontal bar then push colours
        // if (['bar', 'horizontalBar'].indexOf(this.config.type) != -1)
        if (this.config.type === 'bar') {
            let data = chartData.data && chartData.data.length ? chartData.data : chartData;
            let init = chartData.init && chartData.init.length ? chartData.init : null;

            if (init) {
                for (let i in data) {
                    let backgroundColor = colours['green'][0];
                    let hoverBackgroundColor = colours['green'][1];

                    this.data.data.push({
                        label: data[i].label,
                        data: data[i].values,
                        labels: data[i].labels,
                        links: data[i].links && data[i].links.length > 0 ? data[i].links : [],

                        backgroundColor: backgroundColor,
                        hoverBackgroundColor: hoverBackgroundColor,
                        labelFontSize: 4,
                        hidden: i == 0 ? false : true
                    });
                }

                for (let i in init) {
                    let colour = init[i].colour;

                    this.data.datasets.push({
                        label: init[i].label,
                        data: data[0].values[i],
                        borderColor: colours[colour][1],
                        backgroundColor: colours[colour][0],
                        hoverBackgroundColor: colours[colour][1],
                        links: data[0].links,
                        dataType: init[i].dataType,
                    });
                }
            } else {
                let index = 0;
                for (let i in data) {
                    if (data[i].values.length) {
                        let componentData = data[i];

                        // get colours
                        let backgroundColor = colours['blue'][0];
                        let hoverBackgroundColor = colours['blue'][1];

                        let optionalColour = componentData.component ? componentData.component : componentData.colour;

                        // if oee component exists, use corresponding colour, else use the standard blue
                        if (optionalColour) {
                            backgroundColor = colours[optionalColour][0];
                            hoverBackgroundColor = colours[optionalColour][1];
                        }

                        let newData = {
                            label: componentData.label,
                            data: componentData.values,
                            labels: componentData.labels,
                            links: componentData.links && Object.keys(componentData.links).length > 0 ? Object.values(componentData.links) : [],

                            backgroundColor: backgroundColor,
                            hoverBackgroundColor: hoverBackgroundColor,
                            labelFontSize: 4
                        };

                        if (this.options.scales.x.stacked) {
                            this.data.datasets.push(newData);
                        } else {
                            this.data.data.push(newData);

                            this.data.data[index].hidden = index > 0;

                            if (!this.data.data[index].hidden) {
                                this.data.labels = componentData.labels;
                                this.data.datasets[0] = this.data.data[index];
                            }
                        }
                    }

                    index++;
                }
            }
        }

        // if chart isn't bar
        else {
            // reset data
            let yAxisId = 0;
            // let yAxesCount = this.scales.y.length;
            // let yAxesCount = 0;
            for (let i in chartData) {
                if (i != 'labels') {
                    this.data.datasets.push({
                        label: chartData[i].label,
                        data: chartData[i].values,
                        yAxisID: 'y',
                        fill: false,
                        borderColor: colours[i][0],
                        backgroundColor: colours[i][0],
                        pointBackgroundColor: '#ffffff',
                        pointHoverBackgroundColor: '#ffffff',
                        pointHoverBorderColor: colours[i][1],
                        labelFontSize: 4,
                        hidden: chartData[i].hidden,
                        trendline: false,
                    });
                }
            }
        }
    }

    // update chart
    this.update();

    // get canvas, containers, and spinner/loader
    let $canvas = $(this.canvas);
    let $canvasContainer = $canvas.parent();
    let $chartContainer = $canvasContainer.parent();
    let $spinner = $chartContainer.find('.spinner');
    let $donutPercent = $chartContainer.find('.donut-percent');
    let $awayFromTarget = $chartContainer.find('.away-from-target');

    // if donut then set the percentage label value
    if (this.config.type == 'doughnut') {
        let donutText = chartData.values[0] + '%';
        if (chartData.donut_text && chartData.donut_text !== null) {
            donutText = chartData.donut_text;
        }
        $donutPercent.text(donutText);

        if (target) {
            const awayFromTarget = chartData.values[0] - target;
            $awayFromTarget.find('span').text(Math.abs(awayFromTarget).toFixed(2) + '%');

            if (awayFromTarget < 0) {
                $awayFromTarget.addClass('negative');
            }

            $awayFromTarget.removeClass('invisible');
        } else {
            $awayFromTarget.remove();
        }
    }

    // bring chart into view
    $canvasContainer.removeClass('invisible');
    $spinner.remove();
}

export const chartDefaults = Chart.defaults;

export { lineOptions, filledLineOptions };

export { donutOptions };

export {
    Chart,
    ChartDataLabels,
    barOptions,
    horzBarOptions,
    stackedBarOptions,
    horzStackedBarOptions,
    barOptionsWithLegend,
    horzBarOptionsWithLegend,
    multiBarOptionsWithLegend,
    horzMultiBarOptionsWithLegend,
    stackedMultiBarOptionsWithLegend,
    horzStackedMultiBarOptionsWithLegend
};
