import $ from 'jquery';
export default class Checklist {

    constructor(name) {

        this.name = name;
        this.registerEvents();
        this.registerUniqueEvents(name);

    }

    search(name) {
        let search = name.toLowerCase();
        $('.checklist-search + .checklist-choose').find(".toggle-checklist").addClass('chevron-inverted');
        $('.checklist-search + .checklist-choose').find(".checklist-items").css('display', 'block');
        $('.checklist-search + .checklist-choose').find(".checklist-items li").each(function() {
            var name = $(this).attr('data-item-name').toLowerCase();
            if(name.indexOf(search) > -1) {
                $(this).show();
                $(this).find('.checkbox').removeClass('disabled');
            } else {
                $(this).hide();
                $(this).find('.checkbox').addClass('disabled');
            }
        });

        /*if(item.length > 0)
        {
            this.hideList('.checklist-choose .checklist-items');
            this.revealItem(item);
        }*/

    }

    getGroup(child) {

        return $(child).closest('.checklist-group');

    }

    getList(child) {

        return $(child).closest('.checklist-group').find('.checklist-items');

    }

    getGroupId(group) {

        return $(group).data('checklist-group');

    }

    getCounterpartGroup(group) {

        if($(group).closest('.checklist').hasClass('checklist-choose'))
        {
            return $('.checklist-selected').find('.checklist-group[data-checklist-group="'+this.getGroupId(group)+'"]');
        }
        else if($(group).closest('.checklist').hasClass('checklist-selected'))
        {
            return $('.checklist-choose').find('.checklist-group[data-checklist-group="'+this.getGroupId(group)+'"]');
        }

    }

    getItem(child) {

        let item = $(child).closest('li');

        return {
            id: $(item).data('item-id'),
            group: $(item).data('group') ? $(item).data('group') : $(item).data('group-id'),
            name: $(item).data('item-name')
        };

    }

    getQuantityCounter(child) {

        return this.getGroup(child).find('.quantity-selected');

    }

    getQuantitySelected(list) {

        return parseInt($(list).find('li').length);

    }

    getTotalQuantity(group) {

        return parseInt($(group).find('header .quantity-total').html());

    }

    check(checkbox) {

        $(checkbox).removeClass('checkbox-unchecked').addClass('checkbox-checked');

    }

    uncheck(checkbox) {

        $(checkbox).removeClass('checkbox-checked').addClass('checkbox-unchecked');

    }

    checkAll(group) {

        let checkboxes = $(group).find('.checklist-items .checkbox');

        $(checkboxes).addClass('checkbox-checked').removeClass('checkbox-unchecked');

    }

    uncheckAll(group) {

        let checkboxes = $(group).find('.checklist-items .checkbox');

        $(checkboxes).addClass('checkbox-unchecked').removeClass('checkbox-checked');

    }

    toggleCheckbox(checkbox) {

        if($(checkbox).hasClass('checkbox-checked'))
        {
            this.uncheck(checkbox);
            return 0;
        }
        else
        {
            this.check(checkbox);
            return 1;
        }

    }

    toggleAllCheckbox(checkbox) {

        let group = this.getGroup(checkbox);

        if($(checkbox).hasClass('checkbox-checked'))
        {
            this.uncheckAll(group);
        }
        else
        {
            this.checkAll(group);
        }

    }

    revealItem(item) {

        // reveal list
        let list = this.getList(item);
        if($(list).is(':hidden'))
        {
            this.revealList(list, $(this.getGroup(list).find('header .toggle-checklist')));
        }

        // center item
        $(item).addClass('highlight');
        $(item).get(0).scrollIntoView({
            block: 'center'
        });
        setTimeout(() => {
            $(item).removeClass('highlight');
        }, 1500)


    }

    addItem(list, item, multiple= false) {
        let html = '<li data-item-id="'+item.id+'">'+item.name+'<div class="cross"></div><input type="hidden" name="'+this.name+'['+item.group+']['+item.id+']" value="'+item.id+'"></li>';
        $(list).append(html);

        if(!multiple) this.updateQuantity(list);

        // reveal group
        let group = this.getGroup(list);
        if($(group).is(':hidden'))
        {
            if(!multiple) this.revealGroup(group);
        }

    }

    removeItem(list, itemId) {

        $(list).find('li[data-item-id="'+itemId+'"').remove();

        this.updateQuantity(list);

        // hide group if all child items removed
        let group = this.getGroup(list);
        if($(group).is(':visible') && $(group).find('.checklist-items li').length < 1)
        {
            this.hideGroup(group);
        }

    }

    addAllItems(group) {
        let list = this.getCounterpartGroup(group).find('.checklist-items');

        // clear list to prevent duplicates
        this.removeAllItems(group);

        $(group).find('.checklist-items .checkbox:not(.disabled)').each((i, item) => {
            this.addItem(list, this.getItem(item), true);
        });

        this.updateQuantity(list);

        let groupToReveal = this.getGroup(list);
        if($(groupToReveal).is(':hidden'))
        {
            this.revealGroup(groupToReveal);
        }

    }

    removeAllItems(group) {

        let counterpartGroup = this.getCounterpartGroup(group);
        $(counterpartGroup).find('.checklist-items').empty();

        this.hideGroup(counterpartGroup);
        this.updateQuantity($(counterpartGroup).find('.checklist-items'));

    }

    revealList(list) {

        $(this.getGroup(list)).find('.toggle-checklist').addClass('chevron-inverted');
        $(list).removeClass('hidden');

    }

    hideList(list) {

        $(this.getGroup(list)).find('.toggle-checklist').removeClass('chevron-inverted');
        $(list).addClass('hidden');

    }

    toggleList(list) {

        if($(list).is(':visible'))
        {
            this.hideList(list);
        }
        else
        {
            this.revealList(list);
        }

    }

    revealGroup(group) {

        $(group).show();

        // hide no content placeholder
        if(!$('.checklist-selected + .no-content').hasClass('hidden')) 
        {
            $('.checklist-selected + .no-content').addClass('hidden');
        }

    }

    hideGroup(group) {

        $(group).hide();

        // display no content placeholder
        if($('.checklist-selected .checklist-group:visible').length < 1)
        {
            $('.checklist-selected + .no-content').removeClass('hidden');
        }

    }

    updateQuantity(list) {

        // update for provided group
        let quantity = this.getQuantitySelected(list);
        this.getQuantityCounter(list).html(quantity);

        // update for counterpart group
        let counterpartGroup = this.getCounterpartGroup(this.getGroup(list));
        $(counterpartGroup).find('.quantity-selected').html(quantity);

    }

    registerEvents() {

        // keyup: search
        $('.checklist-search input').on('input', e => {
            this.search($(e.target).val());
        });

        // click: group header checkbox
        $('.checklist-group header .checkbox').on('click', e => {
            this.toggleAllCheckbox(e.target);
            this.toggleCheckbox(e.target);
            let counterpartGroup = this.getCounterpartGroup(this.getGroup(e.target));
            if($(e.target).hasClass('checkbox-checked'))
            {
                this.addAllItems(this.getGroup(e.target));
                // $(counterpartGroup).find('.all-selected').removeClass('hidden'); // hide "All" selected
            }
            else if($(e.target).hasClass('checkbox-unchecked'))
            {
                this.removeAllItems(this.getGroup(e.target));
            }
        });

        // click: group header chevron
        $('.checklist-group header .toggle-checklist').on('click', e => {

            this.toggleList(this.getList(e.target));

        });

        // click: selected: group header cross
        $('.checklist-group header .cross').on('click', e => {

            let counterpartGroup = this.getCounterpartGroup(this.getGroup(e.target));
            this.removeAllItems(counterpartGroup);
            this.uncheck($(counterpartGroup).find('.checkbox'))
            // $(counterpartGroup).find('.all-selected').addClass('hidden'); // hide "All" selected

        });

        // click: selected: item cross
        $('.checklist-items').on('click', '.cross', e => {

            let itemId = $(e.target).closest('li').data('item-id');
            let list = this.getList(e.target);
            let counterpartGroup = this.getCounterpartGroup(this.getGroup(list));

            this.removeItem(list, itemId);
            if(this.getQuantitySelected(list) < this.getTotalQuantity(counterpartGroup))
            {
                this.uncheck($(counterpartGroup.find('header .checkbox'))); // uncheck header box
                // $(counterpartGroup).find('.all-selected').addClass('hidden'); // hide "All" selected
            }
            this.uncheck($(counterpartGroup).find('li[data-item-id="'+itemId+'"] > .checkbox'));

        });

        // click: choose: list checkbox
        $('.checklist-items .checkbox').on('click', e => {

            let group = this.getGroup(e.target);
            let counterpartGroup = this.getCounterpartGroup(group);
            let list = $(counterpartGroup).find('.checklist-items');
            let item = this.getItem(e.target)

            if(this.toggleCheckbox(e.target))
            {
                this.addItem(list, item);
            }
            else
            {
                this.removeItem(list, item.id);
                if(this.getQuantitySelected($(counterpartGroup).find('.checklist-items')) < this.getTotalQuantity(group))
                {
                    this.uncheck($(group.find('header .checkbox')));
                }
            }

        });

    }

    registerUniqueEvents(checklistType) {

        switch(checklistType)
        {
            case 'quick-filter':
                
                // change: time period: toggle & clear number input
                $('#checklist-form .table-form select[name="time-period"]').on('change', e => {
        
                    let numberPeriod = $(e.currentTarget).next('input[type="number"]')[0];

                    if(numericPeriods.includes(e.currentTarget.value))
                    {
                        $(numberPeriod).removeClass('hidden');
                        numberPeriod.required = true;
                    }
                    else
                    {
                        $(numberPeriod).addClass('hidden').prop('required', false);
                        numberPeriod.value = '';
                    }
        
                });

            break;
        }
    }
    

}

