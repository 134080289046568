import $ from 'jquery';
export default class TdTooltip {
    constructor(selector) {
        $(selector).find('td').each(function() {
            let cell = $(this);
            if (cell[0].scrollWidth > cell.outerWidth() + 1) {
                cell.on('mouseenter', function() {
                    let tooltip = $('<div class="tooltip"></div>').text(cell.text());
                    $('body').append(tooltip);

                    let cellOffset = cell.offset();
                    let cellWidth = cell.outerWidth();
                    let tooltipWidth = tooltip.outerWidth();

                    // Calculate the left position to center the tooltip box
                    let tooltipLeft = cellOffset.left + (cellWidth - tooltipWidth) / 2;
                    let tooltipTop = cellOffset.top + cell.outerHeight();

                    tooltip.css({ left: tooltipLeft, top: tooltipTop });
                    tooltip.on('mouseenter', function() {
                        $(this).stop(true).fadeIn();
                    }).on('mouseleave', function() {
                        $(this).stop(true).fadeOut(function() {
                            $(this).remove();
                        });
                    });

                    tooltip.fadeIn();
                }).on('mouseleave', function() {
                    $('.tooltip').stop(true).fadeOut(function() {
                        $(this).remove();
                    });
                });
            }
        });
    }
}

