import $ from 'jquery';
export default class AutoRefresh {

    constructor(duration, view = 'dashboard') {

        this.active = false;

        this.trigger = $('.auto-refresh');
        this.view = view;

        this.refresh = {
            handler: null,
            duration: duration
        };

        this.registerEvents();

    }

    startRefreshTimer() {

        if(this.refresh)
        {
            this.refresh.handler = setTimeout(function() {
                window.location.reload();
            }, this.refresh.duration);
        }

    }

    cancelRefreshTimer() {

        if(this.refresh) clearTimeout(this.refresh.handler);

    }

    start() {

        this.startRefreshTimer();

        this.active = true;
        sessionStorage.setItem('auto-refresh', this.view);

        $(this.trigger).children('.svg').addClass('active');

    }

    stop() {

        this.cancelRefreshTimer();

        this.active = false;
        sessionStorage.removeItem('auto-refresh');

        $(this.trigger).children('.svg').removeClass('active');

    }

    toggle() {

        this.active ? this.stop() : this.start();

    }

    registerEvents() {

        this.trigger.on('click', e => {

            this.toggle();

        });

    }

}
