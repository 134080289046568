import $ from 'jquery';
export default class AddFieldset {

    constructor(form) {

        this.form = form;
        this.addFieldsetBtn = '.add-fieldset';
        this.removeFieldsetBtn = '.remove-fieldset';
        this.noDataText = '.no-data';
        this.registerEvents();

    }

    allowRemoval(fieldset) {

        let $close = $(fieldset).find('.remove-fieldset');
        if($close.hasClass('hidden'))
        {
            $close.removeClass('hidden');
        }

    }

    hideFieldset(fieldset) {

        $(fieldset).addClass('hidden').prop('disabled', true);

        $(fieldset).closest('.module').find(this.noDataText).toggleClass('hidden');

    }

    revealFieldset(fieldset) {

        $(fieldset).removeClass('hidden').prop('disabled', false);

        $(fieldset).closest('.module').find(this.noDataText).toggleClass('hidden');

    }

    removeFieldset(fieldset) {

        let fieldsetCount = $(fieldset).closest('.module').find('fieldset').length;
        let minFieldsets = $(fieldset).closest('.module').data('min-fieldsets');

        if(fieldsetCount == minFieldsets)
        {
            alert(`You must have at least ${minFieldsets} of this fieldset.`);
        }
        else
        {
            if(fieldsetCount === 1)
            {
                this.hideFieldset(fieldset);
            }
            else
            {
                $(fieldset).remove();
            }
        }

    }

    incrementNames(fieldset, length) {
        
        var names = [];
        
        // get field names
        for(var field of fieldset[0].elements) 
        {
            names.push(field.name);
        }

        // increment array index
        for(var name of names) 
        {
            if(name.indexOf('[0]') !== 0)
            {
                $(fieldset[0]).find('[name="'+name+'"]').attr('name', name.replace('[0]', '['+length+']'));    
            }
        }

    }

    incrementSwitchIds(fieldset, fieldsetCount) {

        let switches = $(fieldset).find('.form-switch');

        if(switches.length > 0)
        {
            for(let s of switches)
            {
                // note: last character of the id string must be the index!
                let id = $(s).prop('for');
                let newId = id.substring(0, id.length-1) + fieldsetCount;

                $(s).prop('for', newId);
                $(s).prev('input').prop('id', newId)
            }
        }

    }

    resetValues(fieldset) {

        $(fieldset).find('input:not([type="checkbox"]):not([type="time"])').val('');
        $(fieldset).find('input[type="checkbox"][data-checkbox-reset]').prop('checked', false);
        $(fieldset).find('input[type="number"]').val(0);
        $(fieldset).find('select option[value=""]').prop('selected', true);

    }

    addFieldset(module) {

        let fieldsets = $(module).find('fieldset');
        let fieldsetCount = fieldsets.length;
        
        // display and enable hidden fieldset
        if((fieldsetCount === 1 && $(fieldsets[0]).is(':disabled'))
            || (fieldsetCount === 1 && $(fieldsets[0]).hasClass('hidden')))
        {
            this.revealFieldset(fieldsets[0]);
        }

        // duplicate existing fieldset and increment name index
        else if(fieldsetCount > 0)
        {
            let newFieldset = $(fieldsets[fieldsetCount-1]).clone();
            
            // Remove select2 html
            newFieldset.find('.select2-container').remove();
            
            // find select with data-select2-id attribute and remove attribute
            newFieldset.find('select[data-select2-id]')
                .removeAttr('data-select2-id')
                .removeAttr('aria-hidden')
                .removeAttr('tabindex')
                .removeClass('select2-hidden-accessible')
                .val(null)
                .find('option').removeAttr('data-select2-id');
            
            this.allowRemoval(newFieldset);
            this.incrementNames(newFieldset, fieldsetCount);
            this.incrementSwitchIds(newFieldset, fieldsetCount);
            this.resetValues(newFieldset);
            
            $(newFieldset).removeClass('locked');

            $(newFieldset).find('.data-locked').remove();
            $(newFieldset).find('input[type="hidden"]').remove();
            $(newFieldset).find('.input-group-id').remove();
            $(newFieldset).find('[data-clone-state="hidden"]').addClass('hidden');
            $(newFieldset).prependTo($(module).find('.module-content'));
            
            let productLogSkuSelect = newFieldset.find('select[name="product_log['+fieldsetCount+'][product_sku_id]"]');
            let wasteLogSkuSelect = newFieldset.find('select[name="waste_log['+fieldsetCount+'][product_sku_id]"]');

            let productLogSkuSelect2 = new SkuSelect({
                element: productLogSkuSelect,
                placeholder: '',
                useLineId: true
            });
            productLogSkuSelect2.init();

            let wasteLogSkuSelect2 = new SkuSelect({
                element: wasteLogSkuSelect,
                placeholder: '',
            });
            wasteLogSkuSelect2.init();

            // triggers
            if(typeof $(newFieldset).find('select').data('trigger') !== 'undefined')
            {
                var trigger = $(newFieldset).find('select').data('trigger');
                let selects = $(newFieldset).find('select[data-trigger="'+trigger+'"]');
                for(let select of selects)
                {
                    $(select).trigger(trigger);
                }
            }
            
        }

    }

    registerEvents() {

        this.form.on('click', this.addFieldsetBtn, e => {

            this.addFieldset($(e.target).closest('.module'));

        });

        this.form.on('click', this.removeFieldsetBtn, e => {

            this.removeFieldset($(e.target).closest('fieldset'));

        });

    }

}
