export const dataLinks = {

    id: 'data-links',

    beforeInit: (chart) => {
        chart.options.onClick = (event) => {
            // get target chart
            const targetChart = event.chart;
            
            // check if chart is stacked
            const stacked = targetChart.options.type === 'bar' && 
            (targetChart.options.scales.x.stacked || targetChart.options.scales.y.stacked) 
            ? true : false;

            // get interaction point
            const point = targetChart.getElementsAtEventForMode(event, 'nearest', {intersect: true}, true);
            
            if (point[0]) {
                
                // get data and links
                let dataset;
                let selectedIndex;

                if(stacked) {
                    // horizontal segment
                    let datasetIndex = point[0].datasetIndex;
                    
                    // vertical segment
                    selectedIndex = point[0].index;
                    
                    // get dataset
                    dataset = targetChart.data.datasets[datasetIndex];
                } else {
                    selectedIndex = point[0].index;
                    dataset = targetChart.data.datasets[0];
                }

                const links = dataset.links;

                // if links exist
                if (links && links.length > 0) {

                    // redirect to url with link filters
                    const url = links[selectedIndex];
                    window.location.assign(url);

                }

            }

        }
        
    }

};
