import $ from 'jquery';
export default class SkuSelect {
    constructor(options) {
        let protocol = window.location.protocol;
        let hostname = window.location.hostname;
        
        this.element = options.element;
        this.productSkuApi = `${protocol}//${hostname}/api/product-skus`;
        this.useLineId = options.useLineId;
        this.placeholder = options.placeholder;
    }
    
    init() {
            let useLineId = this.useLineId;
            this.element.select2({
                placeholder: this.placeholder,
                minimumInputLength: 3,
                matcher: function(params, data) {
                    params.term = params.term || '';
                    if (data.text.toUpperCase().indexOf(params.term.toUpperCase()) === 0) {
                        return data;
                    }
                    return false;
                },
                ajax: {
                    url: this.productSkuApi,
                    dataType: 'json',
    
                    data: function (params) {
                        let lineId;
                        if(useLineId) {
                            lineId = $('select[name="line_id"]').val();
                        }else{
                            lineId = null;
                        }
                        return {
                            search: params.term,
                            line_id: lineId,
                        }
                    },
                    processResults: function (data) {
                        return {
                            results: data.map(function(item) {
                                return {
                                    id: item.id,
                                    text: item.name
                                };
                            })
                        };
                    },
                },
            });
            this.element.on('select2:open', function(e) {
                setTimeout(function() {
                    let select2Container = $(e.target).data('select2').$dropdown;
                    let searchField = select2Container.find('input.select2-search__field');
    
                    if (searchField.length > 0) {
                        searchField.prop('placeholder', 'Search...');
                        searchField.blur();
                        searchField.focus();
                    }
                }, 100);
            });
    }
}

