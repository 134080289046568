export function colorConvert(color_string) {
    this.success = false;
    this.channels = {
        red: 0,
        green: 0,
        blue: 0,
        alpha: 1,
    };
    this.definitions = [
        {
            expression: /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/,
            process: function(bits) {
                return [
                    parseInt(bits[1]),
                    parseInt(bits[2]),
                    parseInt(bits[3])
                ];
            }
        },
        {
            expression: /^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(1|0?\.\d+)\)$/,
            process: function(bits) {
                return [
                    parseInt(bits[1]),
                    parseInt(bits[2]),
                    parseInt(bits[3]),
                    parseFloat(bits[4]),
                ];
            }
        },
        {
            expression: /#(\w{1})(\w{1})(\w{1})$/,
            process: function(bits) {
                return [
                    parseInt(bits[1] + bits[1], 16),
                    parseInt(bits[2] + bits[2], 16),
                    parseInt(bits[3] + bits[3], 16)
                ];
            }
        },
        {
            expression: /#(\w{2})(\w{2})(\w{2})$/,
            process: function(bits) {
                return [
                    parseInt(bits[1], 16),
                    parseInt(bits[2], 16),
                    parseInt(bits[3], 16)
                ];
            }
        }
    ];
    this.validate = function(color) {
        var validated_color = (color > 0 && color < 1)
            ? validated_color = (color < 0 || isNaN(color)) ? 0 : ((color > 1) ? 1 : color)
            : validated_color = (color < 0 || isNaN(color)) ? 0 : ((color > 255) ? 255 : color);

        return validated_color;
    }
    this.toRGB = function() {
        var channels = this.channels;
        return 'rgb('+ channels.red +', '+ channels.green +', '+ channels.blue +')';
    }
    this.toRGBA = function(alpha) {
        var channels = this.channels;
        return 'rgba('+ channels.red +', '+ channels.green +', '+ channels.blue +', '+ alpha +')';
    }

    // remove whitespace
    color_string = color_string.replace(/ /g, '').toLowerCase();

    // search through the definitions to find a match
    for (var i = 0; i < this.definitions.length; i++) {
        var expression = this.definitions[i].expression;
        var processor = this.definitions[i].process;
        var bits = expression.exec(color_string);

        if (bits) {
            var alpha = this.channels.alpha;
            var processed = processor(bits);

            // set success to true
            this.success = true;

            this.channels = {
                red: this.validate(processed[0]),
                green: this.validate(processed[1]),
                blue: this.validate(processed[2]),
                alpha: this.validate(processed[3]) || alpha,
            };
        }
    }
}

// example use
// var color = new colorConvert('rgba(123, 123, 123, .7)');
// console.log(color.toRGBA(.3));
