import $ from 'jquery';
export default class MassEdit {

    constructor(form) {

        this.form = form;
        this.registerEvents();

    }

    updateCellsWithMaster(input) {

        let cell = $(input).parent().data('master');

        switch(input.tagName)
        {
            case 'INPUT':
                $(this.form).find('td[data-cell="'+cell+'"] input').val($(input).val());
                break;
                
            case 'SELECT':
                $(this.form).find('td[data-cell="'+cell+'"] select').val($(input).val());
            break;
        }

    }

    deleteRow(cell) {

        $(cell).closest('tr').remove();

    }

    registerEvents() {

        // keyup: master input: copy content to all cells in respective column
        $(this.form).on('keyup', 'td[data-master] input', e => {

            this.updateCellsWithMaster(e.target);

        });

        // on change: master input: copy content to all cells in respective column
        $(this.form).on('change', 'td[data-master] select', e => {

            this.updateCellsWithMaster(e.target);

        });

        // click: row delete        
        $(this.form).on('click', '.row-delete', e => {

            this.deleteRow(e.target);

        });
        
    }

}

