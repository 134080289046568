import $ from 'jquery';
export default class OeeDataForm {

    constructor() {

        const protocol = window.location.protocol;
        const hostname = window.location.hostname;

        this.valid = true;
        this.overrideValidation = false;

        this.$form = $('#oee-data-form');
        this.specDialog = document.querySelector('#productSkuSpec');

        this.machineApi = `${protocol}//${hostname}/api/machine`;
        this.machineDowntimeApi = `${protocol}//${hostname}/api/machine/downtime`;
        this.productionDowntimeApi = `${protocol}//${hostname}/api/production/downtime`;
        this.concurrentDowntimeApi = `${protocol}//${hostname}/api/downtime/concurrent`;
        this.productUnitApi = `${protocol}//${hostname}/api/product/measurement-unit`;
        this.potentialTargetApi = `${protocol}//${hostname}/api/product/sku/potential-target`;
        this.avgPotentialTargetApi = `${protocol}//${hostname}/api/product/sku/avg-potential-target`;
        this.productSkuSpecApi = `${protocol}//${hostname}/api/product/sku/spec`;
        this.productDefectApi = `${protocol}//${hostname}/api/product/defect`;
        this.performanceLossApi = `${protocol}//${hostname}/api/performance/loss`;
        this.wasteLocationApi = `${protocol}//${hostname}/api/waste/location`;

        this.registerEvents();

    }


    /*--- produced: get: product sku info ------------------------------------------------------*/


    getProductSkuInfo() {

        let i = 0;
        let skus = [];

        this.$form.find('.produced-fieldset').each(function() {
            skus[i] = [];
            skus[i]['id'] = $(this).find('.input-group-product-sku select').val();
            skus[i]['unit'] = $(this).find('select.measurement-unit').val();
            i++;
        });

        return skus;

    }
        

    /*--- produced: async get: potential target ------------------------------------------------------*/


    async getAvgPotentialTarget(productSkuInfo) {
        
        return $.get(this.avgPotentialTargetApi, {

            'product_sku_ids': productSkuInfo.map(item => item.id),
            'measurement_units': productSkuInfo.map(item => item.unit)

        });

    }


    /*--- produced: async get: product sku spec ------------------------------------------------------*/


    async getProductSkuSpec(productSkuId) {

        return $.get(this.productSkuSpecApi, {

            'product_sku_id': productSkuId

        });

    }


    /*--- produced: get: total produced ------------------------------------------------------*/


    getTotalProduced() {

        let totalProduced = 0;
        for(let producedInput of this.$form.find('.input-group-produced input'))
        {
            totalProduced += Number($(producedInput).val());
        }
        return totalProduced;

    }


    /*--- produced: toggle: performance logs ------------------------------------------------------*/


    togglePerformanceLogs(productSkuId = null) {

        // get product sku target and measurement unit
        let productSkuInfo = this.getProductSkuInfo();

        if(productSkuInfo.length > 0)
        {
            this.getAvgPotentialTarget(productSkuInfo).then(avgPotentialTarget => {

                if(avgPotentialTarget > 0)
                {
                    let dataDuration = this.$form.find('.oee-data-duration input[name="runtime[duration]"]')[0].value;
                    let downtimeDuration = this.getTotalDowntimeDuration();
                    let timeAdjustedPotentialTarget = getTimeAdjustedTarget(avgPotentialTarget, dataDuration, downtimeDuration);

                    let totalProduced = this.getTotalProduced();
                    let $performanceLossSect = this.$form.find('.performance-loss-sect');
                    
                    if(totalProduced < timeAdjustedPotentialTarget)
                    {
                        $performanceLossSect.removeClass('hidden')
                        $performanceLossSect.find('select').prop('disabled', false);
                    }
                    else
                    {
                        $performanceLossSect.addClass('hidden');
                        $performanceLossSect.find('select').prop('disabled', true);
                    }
                }

            });
        }

    }


    /*--- produced: toggle: product sku spec ------------------------------------------------------*/


    toggleProductSkuSpec(skuInput, productSkuSpec) {

        const sku = skuInput.options[skuInput.selectedIndex].text;
        let $viewSpecBtn = $(skuInput).closest('.input-group').find('.view-spec-btn');

        if(productSkuSpec.length > 0)
        {
            $viewSpecBtn.removeClass('hidden');

            this.populateProductSkuSpecDialog(sku, productSkuSpec);
        }
        else
        {
            $viewSpecBtn.addClass('hidden');
        }

    }
    
    /*--- downtimes: toggle: downtime type affecting machine select accessibility and populating lists ------------------------------------------------------*/
    
    toggleDowntimeType(inputGroup) {

        let fieldset = $(inputGroup).closest('fieldset');
        let lineId = this.$form.find('select[name="line_id"]').val();

        switch(inputGroup.value)
        {
            case 'engineering':
                this.loadMachines(fieldset, lineId);
                this.toggleMachineSelect(fieldset, false);
                break;
            case 'production':
                this.loadProductionDowntimes(fieldset, lineId);
                this.toggleMachineSelect(fieldset, true);
                break;
        }

    }


    /*--- downtimes: toggle: machine select accessibility ------------------------------------------------------*/


    toggleMachineSelect(fieldset, disabled) {

        let $select = $(fieldset).find('.input-group-machine select');

        $select.prop('disabled', disabled);

        if(disabled)
        {
            this.clearMachines(fieldset);
        }

    }


    /*--- produced: dialog: populate product sku spec ------------------------------------------------------*/


    populateProductSkuSpecDialog(sku, productSkuSpec) {

        const dialog = document.querySelector('dialog#productSkuSpec');

        dialog.querySelector('#product-sku-spec-inputs').innerHTML = ''; // reset specs
        dialog.querySelector('#product-sku-spec-sku').innerHTML = sku; // set sku title

        productSkuSpec.forEach(e => {
            
            let spec = '<div class="input-group"><span class="pseudo-label">'+e.name+' MIN</span><span class="pseudo-input">'+Intl.NumberFormat().format(e.min)+' '+e.measurement+'</span></div>';
            spec += '<div class="input-group"><span class="pseudo-label">'+e.name+' MAX</span><span class="pseudo-input">'+Intl.NumberFormat().format(e.max)+' '+e.measurement+'</span></div>';

            dialog.querySelector('#product-sku-spec-inputs').innerHTML += spec;

        });


    }


    /*--- produced: clear product sku spec ------------------------------------------------------*/


    clearProductSkuSpec() {

        const dialog = document.querySelector('dialog#productSkuSpec');

        dialog.querySelector('#product-sku-spec-inputs').innerHTML = ''; // reset specs
        dialog.querySelector('#product-sku-spec-sku').innerHTML = ''; // reset sku title

        $('.view-spec-btn').addClass('hidden');

    }


    /*--- downtimes: async get: machines & clear downtimes ------------------------------------------------------*/


    async loadMachines(fieldset, lineId) {

        let $select = $(fieldset).find('.input-group-machine select');
        this.clearDowntimeCategories(fieldset)

        await $.get(this.machineApi, {

            'line_id': lineId

        }).then(machines => {

            $select.empty();

            if(Array.isArray(machines) && machines.length === 0)
            {
                alert('There are no machines associated with this line.\r\nYou can add machines via the Admin module.');
            }
            else
            {
                this.clearMachines($select.closest('fieldset'));

                $select.append('<option value="" selected></option>');
                for(var m of machines)
                {
                    let option = '<option value="'+m.id+'">';
                    if(m.asset_code !== null) option += '['+m.asset_code+'] '; // include asset code if an asset code is provided
                    option += m.name+'</option>';

                    $select.append(option);
                }
            }

        });

    }


    /*--- downtimes: clear: machines ------------------------------------------------------*/


    clearMachines(fieldset) {

        $(fieldset).find('.input-group-machine select').empty();

    }


    /*--- downtimes: async get: production downtimes ------------------------------------------------------*/


    async loadProductionDowntimes(fieldset, lineId) {

        let $select = $(fieldset).find('.input-group-downtime select');

        await $.get(this.productionDowntimeApi, {

            'line_id': lineId

        }).then(downtimes => {

            $select.empty();

            if(Array.isArray(downtimes) && downtimes.length === 0)
            {
                alert('There are no production downtimes associated with this line.\r\nYou can add downtimes via the Admin module.');
            }
            else
            {
                $select.append('<option value="" selected></option>');
                for(var dt of downtimes)
                {
                    let option = `<option value="${dt.id}">${dt.name}</option>`;
                    $select.append(option);
                }
            }

        });

    }


    /*--- downtime: async get: machine downtimes ------------------------------------------------------*/


    async loadMachineDowntimes(machine) {

        let $select = $(machine).closest('fieldset').find('.input-group-downtime select');

        await $.get(this.machineDowntimeApi, {

            'machine_id': machine.value

        }).then(downtimes => {

            $select.empty();

            if(Array.isArray(downtimes) && downtimes.length === 0)
            {
                alert('There are no downtime categories associated with this machine.\r\nYou can add downtime categories via the Admin module.');
            }
            else
            {
                $select.append('<option value="" selected></option>');
                for(var dt of downtimes)
                {
                    let option = `<option value="${dt.id}">${dt.name}</option>`;
                    $select.append(option);
                }
            }

        });

    }


    /*--- downtimes: clear ------------------------------------------------------*/


    clearDowntimeCategories(fieldset) {

        $(fieldset).find('.input-group-downtime select').empty();

    }


    /*--- downtimes: total downtime ------------------------------------------------------*/


    getTotalDowntimeDuration() {
        
        let duration = 0;

        this.$form.find('.downtime-duration input[type="number"]').each(function() {
            duration += parseFloat($(this).val()) || 0;
        });

        return duration;

    }


    /*--- downtimes: async: check whether a downtime is potentially persistent ------------------------------------------------------*/


    async downtimeExistsForPrevPeriod(downtimeCategoryInput) {

        let $fieldset = $(downtimeCategoryInput).closest('fieldset');
        let $concurrentInput = $fieldset.find('.input-group-concurrent');

        await $.get(this.concurrentDowntimeApi, {

            'oee_start': this.$form.find('input[name="runtime[start]"]').val(),
            'downtime_start': $fieldset.find('.input-group-start input').val(),
            'downtime_log_id': $fieldset.find('.input-group-id input') ? $fieldset.find('.input-group-id input').val() : null,
            'line_id': this.$form.find('select[name="line_id"]').val(),
            'downtime_category_id': $(downtimeCategoryInput).val()

        }).then(concurrent => {
            if (concurrent === true)
            {
                $concurrentInput.removeClass('hidden');
            }
            else
            {
                $concurrentInput.addClass('hidden');
                $concurrentInput.find('input').prop('checked', false).trigger('change');
            }
        });

    }


    /*--- waste: async get: defects ------------------------------------------------------*/


    async loadProductDefects(productSku) {

        let $select = $(productSku).closest('fieldset').find('.input-group-waste-product-defect select');

        await $.get(this.productDefectApi, {

            'product_sku_id': productSku.value

        }).then(defects => {

            $select.empty();

            if(Array.isArray(defects) && defects.length === 0)
            {
                alert('There are no defects associated with this product.\r\nYou can add defects via the Admin module.');
            }
            else
            {
                $select.append('<option selected></option>');
                for(var d of defects)
                {
                    let option = `<option value="${d.id}">${d.name}</option>`;
                    $select.append(option);
                }
            }

        });

    }


    /*--- produced: async get: performance losses ------------------------------------------------------*/


    async loadPerformanceLosses(lineId) {

        let $select = this.$form.find('.input-group-performance-loss select');

        await $.get(this.performanceLossApi, {

            'line_id': lineId

        }).then(categories => {

            $select.empty();

            if(Array.isArray(categories) && categories.length === 0)
            {
                alert('There are no reasons for loss associated with this line.\r\nYou can add reasons for loss via the Admin module.');
            }
            else
            {
                $select.append('<option value="" readonly selected>Select a reason for loss...</option>');
                for(var l of categories)
                {
                    let option = `<option value="${l.id}">${l.name}</option>`;
                    $select.append(option);
                }
            }

        });

    }


    /*--- waste: async get: waste locations ------------------------------------------------------*/


    async loadWasteLocations(lineId) {

        let $select = this.$form.find('.input-group-waste-location select');

        await $.get(this.wasteLocationApi, {

            'line_id': lineId

        }).then(locations => {

            $select.empty();

            if(Array.isArray(locations) && locations.length === 0)
            {
                alert('There are no waste locations associated with this line.\r\nYou can add waste locations via the Admin module.');
            }
            else
            {
                $select.append('<option selected></option>');
                for(var l of locations)
                {
                    let option = `<option value="${l.id}">${l.name}</option>`;
                    $select.append(option);
                }
            }

        });

    }


    /*--- produced: clear: product sku ------------------------------------------------------*/


    clearProductSku(lineId) {

        if(lineId === null) return;

        let $select = this.$form.find('.input-group-product-sku select');
        $select.empty();

        this.clearProductSkuSpec();

    }

    
    /*--- waste: populate waste sku using product sku and populate respective defects ------------------------------------------------------*/


    autopopulateWasteSku(sku, name) {
        let $firstWasteSelect = $(this.$form.find('.input-group-waste-product')[0]).find('select');

        if($firstWasteSelect.val() === null)
        {
            $firstWasteSelect.empty();
            let option = document.createElement('option');
            option.value = sku;
            option.text = name;
            $firstWasteSelect.append(option);
            $firstWasteSelect.val(sku);
            this.loadProductDefects($firstWasteSelect[0]);
        }
    }


    /*--- oee data: update end time based on start ------------------------------------------------------*/


    updateDataEnd(start, minutes) {

        // add minutes to start to get end
        let end = new Date(start.getTime() + minutes * 60000);

        // update dom
        let inputGroup = this.$form.find('#module-add-oee-data').find('.oee-data-end');
        inputGroup.find('input[name="datetime_runtime[end]_date"]').val(end.toISOString().split('T')[0]);

        let endHours = end.getHours().toString().padStart(2, '0');
        let endMinutes = end.getMinutes().toString().padStart(2, '0');

        inputGroup.find('input[name="datetime_runtime[end]_time"]').val(`${endHours}:${endMinutes}`);
        inputGroup.find('input[name="runtime[end]"]').val(end.toISOString());
    }


    /*--- oee data: set data duration measurement ------------------------------------------------------*/


    setDataDurationMeasurement(unit = 'minutes') {

        let whitelist = [
            'minutes',
            'hours',
            'days',
            'weeks',
            'months'
        ];

        if($.inArray(unit, whitelist) !== -1)
        {
            this.$form.find('.oee-data-duration select[name="runtime[measurement]"]').val(unit);
        }
        else
        {
            alert('Invalid duration measurement unit: ' + unit);
        }

    }

    
    /*--- oee data: update data duration based on start and end times ------------------------------------------------------*/
    

    updateDataDuration(start, end) {

        let diff = Math.abs(new Date(end)) - Math.abs(new Date(start));

        let duration = diff < 0 ? 0 : Math.floor((diff / 1000) / 60);

        this.$form.find('input[name="runtime[duration]"]').val(duration);
        this.setDataDurationMeasurement();

    }


    /*--- produced: async get: product measurement unit ------------------------------------------------------*/


    async getProductUnit(productSkuId) {

        return $.get(this.productUnitApi, {

            'product_sku_id': productSkuId

        });

    }


    /*--- produced: update production unit ------------------------------------------------------*/


    updateProductionUnit(fieldset, productionUnit) {

        $(fieldset).closest('fieldset').find('.input-group-produced .measurement-unit').val(productionUnit);

    }


    /*--- downtime: set downtime start based on data period start ------------------------------------------------------*/


    updateDowntimeStart(time) {

        for(let downtime of $('#module-add-downtimes fieldset'))
        {
            $(downtime).find('input[type="time"]').val(time);
        }

    }


    /*--- oee data: dialog: display if data duration > 24 hours ------------------------------------------------------*/


    validateDataDuration(e) {

        const dialog = document.querySelector('#confirmDuration');
        let duration = $(e.target).find('input[name="runtime[duration]"]')[0].valueAsNumber;

        if(duration > 1440)
        {
            this.valid = false;
            dialog.showModal();
        }

    }


    /*--- validation ------------------------------------------------------*/


    validate(e) {

        this.validateDataDuration(e);

        if(this.valid === false)
        {
            e.preventDefault();
        }

    }

    
    /*--- events: oee data ------------------------------------------------------*/


    oeeDataEvents() {


        /*--- oee data: change: line ------------------------------------------------------*/


        this.$form.on('change', 'select[name="line_id"]', e => {

            let downtimeFieldsets = $('#module-add-downtimes').find('fieldset');
            this.loadPerformanceLosses(e.target.value);
            this.loadMachines(downtimeFieldsets, e.target.value);
            this.loadWasteLocations(e.target.value);
            this.clearProductSku(e.target.value);
            
            if(this.$form.find('.input-group-downtime-type select').val() === 'production')
                this.loadProductionDowntimes(downtimeFieldsets, e.target.value);

            if(e.target.value !== null) {

                // remove disabled from product SKU select
                this.$form.find('.input-group-product-sku select').prop('disabled', false);
                
            }

        });

        
        /*--- oee data: change: start datetime ------------------------------------------------------*/


        this.$form.on('change', '#module-add-oee-data .oee-data-start', e => {


            let start = $(e.currentTarget).find('input[name="runtime[start]"]').val();
            let end = $(e.currentTarget).closest('.module').find('input[name="runtime[end]"]').val();
            
            this.togglePerformanceLogs();
            
            this.updateDataDuration(start, end);

        });
        

        /*--- oee data: change: end datetime ------------------------------------------------------*/


        this.$form.on('change', '#module-add-oee-data .oee-data-end', e => {
            let end = $(e.currentTarget).find('input[name="runtime[end]"]').val();
            let start = $(e.currentTarget).closest('.module').find('input[name="runtime[start]"]').val();
            
            this.togglePerformanceLogs();

            this.updateDataDuration(start, end);

        });


        /*--- oee data: change: start time ------------------------------------------------------*/

        
        this.$form.on('change', '#module-add-oee-data .oee-data-start input[type="time"]', e => {

            this.updateDowntimeStart(e.target.value);

        });


        /*--- oee data: change: duration ------------------------------------------------------*/


        this.$form.on('keyup', '#module-add-oee-data .oee-data-duration input[name="runtime[duration]"]', e => {
            
            let start = $(e.currentTarget).closest('.module').find('input[name="runtime[start]"]')[0].value;
            start = new Date(start);
            let measurement = $(e.currentTarget).closest('.input-group').find('select[name="runtime[measurement]"]').val();
            let dataDuration = toMinutes($(e.currentTarget).val(), measurement);
            
            this.updateDataEnd(start, dataDuration);

            this.togglePerformanceLogs();
        });
        
    }


    /*--- events: produced ------------------------------------------------------*/


    producedEvents() {

    
        /*--- produced: change: sku ------------------------------------------------------*/


        this.$form.on('change', '.input-group-product-sku select', e => {

            let productSkuId = e.target.value;

            this.getProductUnit(productSkuId).then(productUnit => {

                // update product measurement unit
                this.updateProductionUnit(e.target, productUnit);

                // toggle performance log visibility
                this.togglePerformanceLogs();

                // toggle product sku spec visibility
                this.getProductSkuSpec(productSkuId).then(productSkuSpec => {
                    this.toggleProductSkuSpec(e.target, productSkuSpec);
                });

            });
            
            // get selected sku and name
            let name = e.target.options[e.target.selectedIndex].text;

            // autopopulate waste sku if unpopulated
            this.autopopulateWasteSku(productSkuId, name);

        });

        
        /*--- produced: click: view spec ------------------------------------------------------*/


        this.$form.on('click', '.view-spec-btn', e => {

            this.specDialog.showModal();

        });

        
        /*--- produced: change: quantity ------------------------------------------------------*/


        this.$form.on('change', '.input-group-produced', e => {

            let productSkuId = $(e.target).closest('.inputs').find('.input-group-product-sku select').val();

            // toggle performance log visibility
            if(productSkuId !== null)
            {
                this.togglePerformanceLogs();
            }

        });

    }


    /*--- events: downtime ------------------------------------------------------*/


    downtimeEvents() {

        // change: downtime type
        this.$form.on('change', '.input-group-downtime-type select', e => {

            this.toggleDowntimeType(e.target);

        });

        // change: machine
        this.$form.on('change', '.input-group-machine select', e => {

            this.loadMachineDowntimes(e.target);

        });

        // change: downtime
        this.$form.on('change', '.input-group-downtime select', e => {

            this.downtimeExistsForPrevPeriod(e.target);

        });

        // change: downtime start
        this.$form.on('change', '.input-group-start input', e => {

            const downtimeCategory = $(e.target).parent().parent().find('.input-group-downtime select');
            this.downtimeExistsForPrevPeriod(downtimeCategory);

        });

        // change: downtime duration
        this.$form.on('change', '.downtime-duration input', e => {

            this.togglePerformanceLogs();

        });

    }


    /*--- events: waste ------------------------------------------------------*/


    wasteEvents() {

        // change: waste product
        this.$form.on('change', '.input-group-waste-product select', e => {

            this.loadProductDefects(e.target);

        });

    }
    
    registerEvents() {

        // if line is not selected, disable product SKU and change placeholder text
        if(this.$form.find('select[name="line_id"]').val() === null) {
            
            let $skuSelect = this.$form.find('.input-group-product-sku select');

            $skuSelect.prop('disabled', true);
            $skuSelect.closest('.input-group').find('.select2-selection__placeholder').html('First select a line...');

        }

        this.oeeDataEvents();
        this.producedEvents();
        this.downtimeEvents();
        this.wasteEvents();
        
        // on submit: validation
        this.$form.on('submit', e => {

            if(!this.overrideValidation) this.validate(e);

        });

        // submit form
        // NOTE: the override will also override any subsequent validation errors that haven't yet been displayed
        $('[data-form-force-submit="oee-data-form"]').on('click', e => {

            this.overrideValidation = true;
            this.$form.trigger('submit');

        });

    }

    triggerLine() {

        let $lineInput = this.$form.find('select[name="line_id"]');
        let $performanceLossSelect = this.$form.find('.input-group-performance-loss select');
        let $downtimeTypeInput = this.$form.find('.input-group-downtime-type');
        let $downtimeInput = this.$form.find('.input-group-downtime select');
        let $wasteLocationInput = this.$form.find('.input-group-waste-location select');

        // check if a performance loss has been selected
        let performanceLossExists = $performanceLossSelect.val() !== null ? true : false;

        // check if there are any downtime inputs
        let downtimeExists = false;
        for(let input of $downtimeInput)
        {
            downtimeExists = $(input).val() !== null ? true : false;
            if(downtimeExists) break;
        }

        // check if there are any waste location inputs
        let wasteLocationExists = false;
        for(let input of $wasteLocationInput)
        {
            wasteLocationExists = $(input).val() !== null ? true : false;
            if(wasteLocationExists) break;
        }

        // if no existing waste locations or downtimes will be affected, trigger change
        if(!performanceLossExists && !wasteLocationExists && !downtimeExists && $lineInput.val() !== null)
        {
            $downtimeTypeInput.trigger('change');
            $lineInput.trigger('change');
        }

    }

}

