export function cl(data)
{
    return console.log(...data)
}

export function rand(min, max)
{
    return Math.floor(Math.random() * max) + min;
}

export function escapeHtml(text) {
    var map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;'
    };

    return text.replace(/[&<>"']/g, function(m) { return map[m]; });
}

export function updateCharLimit(event)
{
    // Get current
    var charCount = event.target.textLength;

    // Get max
    var legend = event.target.nextElementSibling;

    // Update remaining
    legend.innerHTML = legend.dataset.max - charCount;
}

// NOTE: may not use, possible unnecessary overhead
export function removeAlerts()
{
    var alerts = document.getElementsByClassName('alert');
    for(alert of alerts)
    {
        // check if alert has run its animation and remove from page
    }
}

export function toMinutes(duration, unit)
{
    duration = parseInt(duration);

    switch(unit)
    {
        case 'minutes':
            return duration;
            break;

        case 'hours':
            return duration * 60;
            break;

        case 'days':
            return duration * 24 * 60;
            break;

        case 'weeks':
            return duration * 7 * 24 * 60;
            break;
    }
}

export function getTimeAdjustedTarget(target, duration, downtime)
{
    return Math.round((target / 60) * (duration - downtime));
}

export function convertRelativeDateToDate(relative_date)
{
    let formatDateUTC = function (date) {
        return date.toISOString().slice(0, 16);
    }

    let date_from;
    let date_to;

    let now = new Date();

    let dayOfWeek;
    let diffToMonday;

    switch (relative_date) {
        case 'today':
            now.setUTCHours(0, 0, 0, 0);
            date_from = formatDateUTC(now);
            now.setUTCHours(23, 59, 59, 999);
            date_to = formatDateUTC(now);
            break;
        case 'yesterday':
            now.setUTCDate(now.getUTCDate() - 1);
            now.setUTCHours(0, 0, 0, 0);
            date_from = formatDateUTC(now);
            now.setUTCHours(23, 59, 59, 999);
            date_to = formatDateUTC(now);
            break;
        case 'this_week':
            dayOfWeek = now.getUTCDay();
            diffToMonday = (dayOfWeek === 0 ? -6 : 1 - dayOfWeek); // Monday is the first day (Sunday is 0)
            now.setUTCDate(now.getUTCDate() + diffToMonday);
            now.setUTCHours(0, 0, 0, 0);
            date_from = formatDateUTC(now);
            now.setUTCDate(now.getUTCDate() + 6); // End of the week (Sunday)
            now.setUTCHours(23, 59, 59, 999);
            date_to = formatDateUTC(now);
            break;
        case 'last_week':
            now.setUTCDate(now.getUTCDate() - 7); // Go back one week
            dayOfWeek = now.getUTCDay();
            diffToMonday = (dayOfWeek === 0 ? -6 : 1 - dayOfWeek);
            now.setUTCDate(now.getUTCDate() + diffToMonday); // Adjust to Monday of last week
            now.setUTCHours(0, 0, 0, 0);
            date_from = formatDateUTC(now);
            now.setUTCDate(now.getUTCDate() + 6); // End of last week (Sunday)
            now.setUTCHours(23, 59, 59, 999);
            date_to = formatDateUTC(now);
            break;
        case 'last_7_days':
            now.setUTCDate(now.getUTCDate() - 7);
            now.setUTCHours(0, 0, 0, 0);
            date_from = formatDateUTC(now);
            date_to = formatDateUTC(new Date()); // Today
            break;
        case 'this_month':
            let thisMonthStart = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 1));
            let thisMonthEnd = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 1, 0));
            thisMonthEnd.setUTCHours(23, 59, 59, 999);
            date_from = formatDateUTC(thisMonthStart);
            date_to = formatDateUTC(thisMonthEnd);
            break;
        case 'last_month':
            let lastMonthStart = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() - 1, 1));
            let lastMonthEnd = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 0));
            lastMonthEnd.setUTCHours(23, 59, 59, 999);
            date_from = formatDateUTC(lastMonthStart);
            date_to = formatDateUTC(lastMonthEnd);
            break;
        case 'last_9_weeks':
            now.setUTCDate(now.getUTCDate() - 9 * 7);
            date_from = formatDateUTC(now);
            date_to = formatDateUTC(new Date());
            break;
        case 'last_3_months':
            now.setUTCMonth(now.getUTCMonth() - 3);
            date_from = formatDateUTC(now);
            date_to = formatDateUTC(new Date());
            break;
        case 'last_6_months':
            now.setUTCMonth(now.getUTCMonth() - 6);
            date_from = formatDateUTC(now);
            date_to = formatDateUTC(new Date());
            break;
        case 'this_year':
            date_from = formatDateUTC(new Date(Date.UTC(now.getUTCFullYear(), 0, 1)));
            date_to = formatDateUTC(new Date(Date.UTC(now.getUTCFullYear(), 11, 31, 23, 59, 59, 999)));
            break;
        case 'last_year':
            let lastYear = now.getUTCFullYear() - 1;
            date_from = formatDateUTC(new Date(Date.UTC(lastYear, 0, 1)));
            date_to = formatDateUTC(new Date(Date.UTC(lastYear, 11, 31, 23, 59, 59, 999)));
            break;
    }

    return { date_from: date_from, date_to: date_to };
}

// recursively search for a key in an object
export function findValueByKey(obj, key)
{
    // loop through all keys in the current object
    for (let i in obj) {

        if (obj.hasOwnProperty(i))
        {
            // if current key matches, return its value
            if (i === key)
            {
                return obj[i];
            }

            // if the value is an object, recursively search it
            if (typeof obj[i] === 'object')
            {
                const result = findValueByKey(obj[i], key);
                if (result)
                {
                    return result;
                }
            }
        }
    }

    // return null if the key wasn't found
    return null;
}
