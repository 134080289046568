import $ from 'jquery';
export default class OeeTargetCalculator {

    constructor(form, options = null) {

        this.form = form;
        
        this.targets = options !== null && typeof options.targets !== 'undefined' ? options.targets : {
            a: 'input[data-target="a"]',
            p: 'input[data-target="p"]',
            q: 'input[data-target="q"]',
            oee: 'input[data-target="oee"]'
        };

        this.inputs = this.targets.a + ', ' + this.targets.p + ', ' + this.targets.q;
        this.registerEvents();
    
    }

    getComponentTargets() {

        return {

            a: this.form.find(this.targets.a).val(),
            p: this.form.find(this.targets.p).val(),
            q: this.form.find(this.targets.q).val()

        };

    }
    
    calculateOeeTarget() {

        let targets = this.getComponentTargets();

        return ((targets['a'] / 100) * (targets['p'] / 100) * (targets['q'] / 100)) * 100;

    }
    
    updateOeeTarget() {
        
        this.form.find(this.targets.oee).val(this.calculateOeeTarget().toFixed(2));
        
    }

    registerEvents() {

        // keyup: oee target inputs        
        $(this.form).on('keyup', this.inputs, e => {

            this.updateOeeTarget();
        
        });
        
    }

}

