import $ from 'jquery';
export default class MultiSelect {

    constructor(form) {

        this.form = $(form);
        this.actionSelect = $(form).find('.multi-select-action');
        this.registerEvents();

    }
    
    getGroup(child) {
        
        return $(child).closest('.checklist-group');
        
    }

    showActionSelect() {

        $(this.actionSelect).css('display', 'inline-flex');

    }

    hideActionSelect() {

        $(this.actionSelect).css('display', 'none');

    }

    checkAll(masterCheckbox) {

        $(masterCheckbox).closest('table').find('tbody .multi-select-checkbox').prop('checked', true);        
        this.showActionSelect();

    }

    uncheckAll(masterCheckbox) {

        $(masterCheckbox).closest('table').find('tbody .multi-select-checkbox').prop('checked', false);
        this.hideActionSelect();

    }

    toggleCheckAll(masterCheckbox) {
        
        if($(masterCheckbox).is(':checked'))
        {
            this.checkAll(masterCheckbox);
        }
        else
        {
            this.uncheckAll(masterCheckbox);
        }

    }

    registerEvents() {

        // change: checkox checked        
        $(this.form).on('click', '.checkbox', e => {

            if($(this.form).find('.checkbox:not(.master-checkbox)').is(':checked'))
            {
                this.showActionSelect();
            }
            else
            {
                this.hideActionSelect();
            }
        
        });

        // click: master checkbox        
        $(this.form).on('click', '.master-checkbox', e => {

            this.toggleCheckAll(e.target);
        
        });

        // change: action select        
        $(this.form).on('change', '.multi-select-action', e => {

            if(e.target.value == 'delete')
            {
                // when the delete option is included
            }
            else
            {
                this.form.submit();
            }
        
        });
        
    }

}

