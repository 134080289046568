import $ from 'jquery';
import CustomChart from './custom-chart.js';

export default class CustomDashboard {
    constructor(options) {
        this.view = options.view;
        this.gridItemsData = options.gridItemsData;
        
        this.dateFrom = options.dateFrom;
        this.dateTo = options.dateTo;
        
        this.lines = options.lines;
        this.machines = options.machines;
        this.downtimeCategories = options.downtimeCategories;
        this.products = options.products;
        this.skus = options.skus;
        this.defects = options.defects;
        this.wasteLocations = options.wasteLocations;
        
        this.filtersParams = new URLSearchParams();

        if (this.lines) this.filtersParams.append('lines', this.lines.join(','));
        if (this.machines) this.filtersParams.append('machines', this.machines.join(','));
        if (this.downtimeCategories) this.filtersParams.append('downtimeCategories', this.downtimeCategories.join(','));
        if (this.products) this.filtersParams.append('products', this.products.join(','));
        if (this.skus) this.filtersParams.append('skus', this.skus.join(','));
        if (this.defects) this.filtersParams.append('defects', this.defects.join(','));
        if (this.wasteLocations) this.filtersParams.append('wasteLocations', this.wasteLocations.join(','));
        if (this.dateFrom) this.filtersParams.append('date_from', this.dateFrom);
        if (this.dateTo) this.filtersParams.append('date_to', this.dateTo);
    }
    
    getGrid(){
        return this.grid;
    }
    
    addTable(gridItem, mainTable=false){
        let grid = this.grid;
        let view = this.view;
        let editUrlParam = view === 'edit' ? '?edit=true&framed=true&' : '?framed=true&';
        
        editUrlParam += this.filtersParams.toString();
        
        let url = '/tools/custom-table/{custom_table}/table-html'.replace('{custom_table}', gridItem.model_id) + editUrlParam;

        let iframe = document.createElement('iframe');
        iframe.src = url;
        iframe.classList.add('iframe-content'); // Add a class instead of inline styles
        let overlay = document.createElement('div');
        
        if(view === 'edit') overlay.classList.add('iframe-drag-handle');
        
        let container = document.createElement('div');
        container.classList.add('iframe-container');
        container.appendChild(overlay);
        container.appendChild(iframe);
        
        let hiddenInputId = document.createElement('input');
        hiddenInputId.type = 'hidden';
        hiddenInputId.name = 'id';
        hiddenInputId.value = gridItem.model_id;
        container.appendChild(hiddenInputId);
        
        let hiddenInputType = document.createElement('input');
        hiddenInputType.type = 'hidden';
        hiddenInputType.name = 'type';
        hiddenInputType.value = 'table';
        container.appendChild(hiddenInputType);
        
        const styles = `
            .iframe-container {
                height:100%;
            }
            .iframe-content {
                width: 100%;
                height: 100%;
                border: none;
            }
            .iframe-drag-handle {
                position: absolute;
                top: 0;
                left: 0;
                height: 52px;
                width: calc(100% - 100px);
                cursor: move;
                background-color: rgba(255, 255, 255, 0);
                z-index: 10;
            }`;

        const styleSheet = document.createElement("style");
        styleSheet.innerText = styles;
        document.head.appendChild(styleSheet);
        
        if(mainTable){
            let tableContainer = document.querySelector('.custom-dashboard-table-view');
            tableContainer.innerHTML = container.outerHTML;
            let iframe = $('.custom-dashboard-table-view iframe');
            iframe.on('load', function(){
                iframe.height(iframe.contents().height() + 20);
            });
        }else{
            if(view === 'show'){
                grid.addWidget({w: gridItem.w, h: gridItem.h, x: gridItem.x, y: gridItem.y, content: container.outerHTML, noResize: true, noMove: true});
            }else{
                grid.addWidget({w: gridItem.w, h: gridItem.h, x: gridItem.x, y: gridItem.y, content: container.outerHTML});
            }   
        }

        const iframes = document.querySelectorAll('.iframe-content');

        iframes.forEach(iframe => {
            iframe.addEventListener('load', function () {
                $(iframe).contents().find('.delete-widget-button').on('click', function (e) {
                    e.preventDefault();
                    if (!confirm('Are you sure you want to delete this widget?')) {
                        return;
                    }

                    let gridItem = $(iframe).closest('.grid-stack-item');
                    let id = gridItem.find('input[name="id"]').val();
                    grid.removeWidget(gridItem[0]);

                    if (gridItem.find('input[name="type"]').val() === 'table' || gridItem.find('input[name="type"]').val() === 'chart') {
                        let select = gridItem.find('input[name="type"]').val() === 'table' ? $('#custom_table_id') : $('#custom_chart_id');
                        select.find('option[value="' + id + '"]').removeAttr('disabled');
                    }
                });
            });
        });
    }
    
    addChart(gridItem){
        let grid = this.grid;
        let view = this.view;

        let editUrlParam = view === 'edit' ? '?edit=true&' : '?';
        
        editUrlParam += this.filtersParams.toString();
        
        let url = '/tools/custom-chart/{custom_chart}/chart-data'.replace('{custom_chart}', gridItem.model_id) + editUrlParam;
        $.get(url, function(response){
            if(view === 'show'){
                grid.addWidget({w: gridItem.w, h: gridItem.h, x: gridItem.x, y: gridItem.y, content: response.html, noResize: true, noMove: true});
            }else{
                grid.addWidget({w: gridItem.w, h: gridItem.h, x: gridItem.x, y: gridItem.y, content: response.html});
            }
            
            new CustomChart({
                data: response.data,
                type: response.type,
                elementId: 'chart-'+gridItem.model_id,
            }).init();
        });
    }
    
    init() {
        let items = [];
        this.grid = GridStack.init({});
        this.grid.load(items);
        this.rememberedLayouts = {};


        const adjustGridColumns = () => {
            let gridStackElement = document.querySelector('.grid-stack');
            let width = gridStackElement.offsetWidth;
            let currentColumnCount = this.grid.opts.column;
            
            let columns;
            
            if (width <= 709) {
                columns = 4;
            } else if (width <= 1170) {
                columns = 8;
            } else {
                columns = 12;
            }

            // remember the layout at current number of cols
            const items = this.grid.engine.nodes;
            
            if(this.rememberedLayouts[currentColumnCount] === undefined) {
                this.rememberedLayouts[currentColumnCount] = [];
                items.forEach(item => {
                    this.rememberedLayouts[currentColumnCount].push({
                        el: item.el,
                        x: item.x,
                        y: item.y,
                        w: item.w,
                        h: item.h
                    });
                });
            }
            
            this.grid.column(columns, 'move');
            
            this.grid.compact();
            
            // restore remembered layout if we have one
            if(this.rememberedLayouts[columns] !== undefined) {
                this.rememberedLayouts[columns].forEach(layout => {
                    this.grid.update(layout.el, {x: layout.x, y: layout.y, w: layout.w, h: layout.h});
                });
            }
            
            setTimeout(function() {
                for (let chartId in window.charts) {
                    window.charts[chartId].resize();
                }
            }, 500);
        };
        
        window.addEventListener('resize', adjustGridColumns);

        this.grid.on('resize', function(event, element) {
            for (let chartId in window.charts) {
                window.charts[chartId].resize();
            }
        });

        let handleStart = function(event, element) {
            const iframe = element.querySelector('iframe');
            if (iframe) {
                iframe.style.pointerEvents = 'none';
            }
        }

        let handleStop = function(event, element) {
            const iframe = element.querySelector('iframe');
            if (iframe) {
                iframe.style.pointerEvents = 'auto';
            }
        }

        this.grid.on('dragstart', (event, element) => handleStart(event, element));
        this.grid.on('resizestart', (event, element) => handleStart(event, element));

        this.grid.on('dragstop', (event, element) => handleStop(event, element));
        this.grid.on('resizestop', (event, element) => handleStop(event, element));
        
        if(this.view === 'edit' || this.view === 'show') {
            // hide the grid while loading
            $('.grid-stack').hide();
            $('.grid-loading').show();

            
            let intervalId;

            let updatePhrase = function() {
                
                // count iframes that are loading
                let iFrames = document.querySelectorAll('iframe');
                let activeLoadingIframes = 0;
                iFrames.forEach(iFrame => {
                    let isLoaded = false;

                    try {
                        if (iFrame.contentWindow.document.readyState === 'complete') {
                            isLoaded = true;
                        }
                    } catch (e) {
                        console.warn(`Cannot access iframe content: ${e.message}`);
                    }
                    if(!isLoaded){
                        activeLoadingIframes++;
                    }
                });
                
                let activeLoading = $.active + activeLoadingIframes;
                
                let loadingPhrase = document.getElementById('loadingPhrase');
                loadingPhrase.innerHTML = 'Loading ('+activeLoading+' requests remaining)';
                
                if(activeLoading === 0){
                    $('.grid-stack').show();
                    $('.grid-loading').hide();
                    clearInterval(intervalId);

                    // on completion of all loading, trigger resize event to adjust grid columns
                    setTimeout(function() {
                        adjustGridColumns();
                    }, 500);
                }
                
            };

            intervalId = setInterval(updatePhrase, 500);
            
            for (let i = 0; i < this.gridItemsData.length; i++) {
                let gridItem = this.gridItemsData[i];
                
                if(gridItem.type === 'table'){
                    this.addTable(gridItem)
                }else if(gridItem.type === 'chart'){
                    this.addChart(gridItem)
                }
            }
        }
    }
}
