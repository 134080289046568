import $ from 'jquery';
export default class GlobalSearch {
    
        constructor() {
    
            this.$modal = $('#global-search');
            this.$form = $('#global-search-form');
            this.$searchInput = $('#global-search-form input[name="search"]');
            this.$categories = $('#global-search-categories');
            this.$table = $('#search-results-table');

            this.typeNames = {
                'Data': {
                    'oee_datum': 'OEE Data',
                },
                'Site': {
                    'line': 'Lines',
                    'machine': 'Machines',
                    'downtime_category': 'Downtime Reasons',
                    'performance_loss_category': 'Performance Losses',
                    'waste_location': 'Waste Locations',
                },
                'Product': {
                    'product': 'Products',
                    'sku': 'SKUs',
                    'defect': 'Defects',
                },
                'User': {
                    'user': 'Users',
                    'user_log': 'User Logs',
                    'comment': 'Comments',
                }
            };

            this.debouncedGetResults = this.debounce(function() {
                return this.getResults();
            }, 500);

            this.buildFilters();
    
            this.registerEvents();
    
        }

        
        /*--- clear search input ------------------------------------------------------*/


        clear() {

            this.$searchInput.val('');

        }


        /*--- close & [optional] reset ------------------------------------------------------*/


        close(reset) {

            if(reset) this.reset();

            this.$modal.removeClass('global-search-visible');

        }


        /*--- reset ------------------------------------------------------*/


        reset() {
            
            this.$categories.empty();
            this.$table.find('tbody').html('<tr><td colspan="4">No results found</td></tr>');
            this.clear();

        }

    
        /*--- build filters ------------------------------------------------------*/
    
    
        buildFilters() {
    
            const $searchFilterChoice = $('#searchFilterChoice');
    
            $.each(this.typeNames, function(category, types) {
                
                const $fieldset = $('<fieldset></fieldset>');
                
                const $heading = $('<p class="fieldset-heading">')
                    .text(category);
                
                $.each(types, function(key, type) {
                    
                    const $input = $('<input>')
                        .attr('id', `global-search-filters-${key}`)
                        .attr('type', 'checkbox')
                        .attr('checked', 'checked')
                        .addClass('checkbox chevron-blue');
        
                    const $label = $('<label></label>')
                        .attr('for', `global-search-filters-${key}`)
                        .text(type);

                    const $checkbox = $('<div class="checkbox-cont"></div>');
                    
                    $checkbox.append($input).append($label);
                    $fieldset.append($checkbox);
                    
                });

                $fieldset.prepend($heading);
                $searchFilterChoice.append($fieldset);
    
            });
    
        }
    
    
        /*--- get results ------------------------------------------------------*/
    
    
        getResults() {
            
            if(this.$searchInput.val().length > 0) {
    
                let categories = [];
    
                // Find all checked checkboxes within #searchFilterChoice and collect their IDs
                $('#searchFilterChoice input[type="checkbox"]:checked').each(function() {
    
                    // Extract the type from the checkbox ID (e.g., from 'global-search-filters-lines' get 'lines')
                    const type = $(this).attr('id').replace('global-search-filters-', '');
                    categories.push(type);
    
                });
                
                fetch(globalSearchRoute, {
    
                    method: 'POST',
    
                    body: JSON.stringify({
                        categories: categories,
                        q: $("#global-search-form input[name='search']").val(),
                        date_from: $("#global-search-filters input[name='datetime_from_date']").val() + " " + $("#global-search-filters input[name='datetime_from_time']").val(),
                        date_to: $("#global-search-filters input[name='datetime_to_date']").val() + " " + $("#global-search-filters input[name='datetime_to_time']").val(),
                    }),
    
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                        'Accept': 'application/json',
                    }
    
                }).then(response => {
    
                    if (!response.ok) {
                        throw new Error('Network error searching.');
                    }
                    return response.json();
    
                }).then(data => {
                    
                    // Get result type
                    data.results.forEach(result => {
                        result.type = findValueByKey(this.typeNames, result.type);
                    });
    
                    // Generate result categories
                    this.generateCategories(data);

                    // Populate results table
                    this.populateTable(data);
    
                })
            }
            else
            {
                $('#globalSearchResultsContainer').hide();
            }
        }


        /*--- generate the result categories ------------------------------------------------------*/


        generateCategories(data) {

            this.$categories.empty();

            // Count each type of result
            let counts = {};
            data.results.forEach(result => {
                
                counts[result.type] ? counts[result.type]++ : counts[result.type] = 1;

            });
    
            // Add the "All Results" category
            const $allResultsGroup = $('<div>').addClass('input-group active').attr('data-type', 'all');
            const $allResultsInput = $('<p>').addClass('pseudo-input');
            const $allResultsNameSpan = $('<span>').text('All Results');
                
            // if there are 101 results, there could be 100+ so add a + sign to all count.
            let has101Results = false;
            
            // Generate the other categories dynamically
            $.each(counts, (type, count) => {

                const $inputGroup = $('<div>').addClass('input-group').attr('data-type', type);
                const $pseudoInput = $('<p>').addClass('pseudo-input');
                const $nameSpan = $('<span>').text(type);
                    
                if(count === 101){
                    count = '100+';
                    has101Results = true;
                }
                
                const $countSpan = $('<span>').text(count);

                $pseudoInput.append($nameSpan).append($countSpan);
                $inputGroup.append($pseudoInput);
                this.$categories.append($inputGroup);
                
            });
                
            let allResultsCount;

            // append + to data.results.length value
            allResultsCount = has101Results ? data.results.length + '+' : data.results.length;
                
            const $allResultsCountSpan = $('<span>').text(allResultsCount);
                
            $allResultsInput.append($allResultsNameSpan).append($allResultsCountSpan);
            $allResultsGroup.append($allResultsInput);
            this.$categories.prepend($allResultsGroup);

        }


        /*--- populate the results table ------------------------------------------------------*/


        populateTable(data) {
            
            // display type column
            $('#search-results-table [data-global-search-col="type"]').show();
            
            // Clear existing table rows in tbody
            $('#search-results-table tbody').empty();

            // if there's no results
            if(data.results.length < 1) 
            {
                $('#search-results-table tbody').html('<tr><td colspan="4">No results found</td></tr>');
            }
     
            // Loop through the results array and append rows to the table
            $.each(data.results, function(index, result) {

                // Create the row and columns
                let $newRow = $('<tr>').attr('data-item-id', result.type);
                let $typeCell = $('<td>').text(result.type).attr('data-global-search-col', 'type');
                let $nameCell = $('<td>').text(result.name);
                let $detailsCell = $('<td>').text(result.details);
                let $optionsCell = $('<td>');
                let $optionsDiv = $('<div>').addClass('flex');

                // Loop through the options array and create links
                if(result.options && result.options.length > 0) 
                {
                    $.each(result.options, function(i, option) {
                        let $link = $('<a>')
                            .attr('href', option.url)
                            .addClass('btn btn-edit')
                            .text(option.label);
                        $optionsDiv.append($link);
                    });
                }

                // Append the div with options to the cell
                $optionsCell.append($optionsDiv);

                // Append all cells to the row
                $newRow.append($typeCell, $nameCell, $detailsCell, $optionsCell);

                // Append the row to the table body
                $('#search-results-table tbody').append($newRow);

            });

        }
    
        /*--- debounce ------------------------------------------------------*/
    
    
        debounce(func, delay) {
            
            let timer;
            
            return function() {

                clearTimeout(timer);

                timer = setTimeout(() => {

                    func.apply(this);

                }, delay);

            };

        }
        

        /*--- events ------------------------------------------------------*/


        registerEvents() {
    
            // event: toggle search overlay
            this.$form.on('keyup', 'input', e => {
        
                // const $globalSearch = $('#global-search');
    
                e.currentTarget.value.trim().length > 0 ? this.$modal.addClass('global-search-visible') : this.$modal.removeClass('global-search-visible');
                    
                this.debouncedGetResults();
    
            });
        
            // event: toggle search filters
            $('#global-search').on('click', '.toggle-search-filters', e => {

                if($(e.delegateTarget).hasClass('search-filters-visible'))
                {
                    // hide filters
                    $(e.delegateTarget).removeClass('search-filters-visible');
                    this.$form.removeClass('search-filters-visible');
                }
                else
                {
                    // display filters
                    $(e.delegateTarget).addClass('search-filters-visible');
                    this.$form.addClass('search-filters-visible');
                }

            });

            // event: toggle search filters
            $('#global-search-filters').on('change', 'input', e => {
                
                this.debouncedGetResults();
                
            });

            // event: handle click event for filtering results by type
            $('#global-search-categories').on('click', '.input-group', function() {

                const type = $(this).attr('data-type');

                // Toggle the active class
                $('.input-group').removeClass('active');
                $(this).addClass('active');

                // Filter table rows based on the selected type
                if(type === 'all') 
                {
                    // Show all rows
                    $('#search-results-table tbody tr').show();
                    $('#search-results-table [data-global-search-col="type"]').show();
                } 
                else 
                {
                    $('#search-results-table tbody tr').hide();
                    $(`#search-results-table tbody tr[data-item-id="${type}"]`).show();
                    $('#search-results-table [data-global-search-col="type"]').hide();
                }
                
            });

                    
            // event: clear and close search modal
            $('.global-search-close').on('click', e => {
                
                this.close(true);
                
            });
    
        }
    
    }
