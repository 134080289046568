export default class Assistant {
    
    init(options){
        
        this.getAIResponseRoute = options.getAIResponseRoute;
        this.apiToken = options.apiToken;
        this.getConversationRoute = options.getConversationRoute;
        
        const chatContainer = document.getElementById("chat-container");
        const chatButton = document.getElementById("chat-button");
        const closeChat = document.getElementById("close-chat");
        const expandChat = document.getElementById("expand-chat");
        const clearChat = document.getElementById("clear-chat");
        
        this.chatBox = document.getElementById("chat-box");
        this.chatInput = document.getElementById("chat-input");

        chatButton.addEventListener("click", () => {
            chatContainer.classList.toggle("open");
        });

        closeChat.addEventListener("click", () => {
            chatContainer.classList.remove("open");
        });

        expandChat.addEventListener("click", () => {
            chatContainer.classList.toggle("expanded");
        });
        
        clearChat.addEventListener("click", () => {
            // set chat box input value to "__clear_conversation__".
            this.chatInput.value = "__clear_conversation__";

            this.sendMessage();

            // clear all .message
            const messages = document.querySelectorAll(".message");
            messages.forEach(message => {
                message.remove();
            });
        });
        
        this.chatInput.addEventListener("keypress", (e) => {
            if (e.key === "Enter") {
                e.preventDefault();
                this.sendMessage();
            }
        });
        
        document.getElementById("chat-send").addEventListener("click", () => {
            this.sendMessage();
        });
        
        this.getConversationHistory();
    }

    csvToMarkdown(csvString) {
        csvString = csvString.replace(/"((?:[^"]|"")*)"/gs, (match, group) => {
            // Replace any newlines within quoted strings with a newline symbol
            const cleaned = group.replace(/\r?\n/g, ' ⏎ ');
            return `"${cleaned}"`;
        });
        
        const rows = csvString.trim().split("\n");

        if (rows.length === 0) return "";

        rows.shift();

        if (rows.length === 0) return "";

        const tableData = rows.map(row =>
            row.match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g)?.map(cell =>
                cell.replace(/^"|"$/g, '').trim()
            ) || []
        );

        if (tableData.length === 0) return "";

        const headers = tableData[0];

        let markdownTable = `| ${headers.join(" | ")} |\n`;
        markdownTable += `|${" --- |".repeat(headers.length)}\n`;

        if (tableData.length === 1) {
            markdownTable += `| No data matching this query${" |".repeat(headers.length - 1)}\n`;
        } else {
            for (let i = 1; i < tableData.length; i++) {
                markdownTable += `| ${tableData[i].join(" | ")} |\n`;
            }
        }

        return markdownTable;
    }

    async sendMessage() {
        let message = this.chatInput.value.trim();
        if (message === "") return;
        this.chatBox.insertAdjacentHTML('beforeend', `<div class="message user-message">${message}</div>`);
        this.chatInput.value = "";

        this.chatBox.scrollTop = this.chatBox.scrollHeight;

        const sendButton = document.getElementById("chat-send");
        sendButton.disabled = true;
        sendButton.classList.add("chat-loading");

        let aiResponse = await this.getAIResponse(message);

        sendButton.disabled = false;
        sendButton.classList.remove("chat-loading");

        await this.insertNewMessages(aiResponse);
        this.chatBox.scrollTop = this.chatBox.scrollHeight;
    }

    async getAIResponse(userMessage) {
        try {
            const response = await fetch(this.getAIResponseRoute, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+this.apiToken,
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content
                },
                body: JSON.stringify({message: userMessage})
            });

            const data = await response.json();
            return data.new_messages;
        } catch (error) {
            console.error("Error fetching AI response:", error);
            return "Sorry, there was an error! Please try again.";
        }
    }

    async insertNewMessages(messages){
        messages.forEach(historyItem => {
            if (historyItem.role === "user") {
                // remove timestamp from start of string
                historyItem.parts[0].text = historyItem.parts[0].text.replace(/^\[.*?\]/, '');

                // if message contains [START-DATA] amd [END-DATA], get text between it
                if (historyItem.parts[0].text.includes('[START-DATA]') && historyItem.parts[0].text.includes('[END-DATA]')) {
                    historyItem.parts[0].text = historyItem.parts[0].text.split('[START-DATA]')[1].split('[END-DATA]')[0];

                    historyItem.parts[0].text = this.csvToMarkdown(historyItem.parts[0].text);

                    const converter = new showdown.Converter({ tables: true });
                    const html = converter.makeHtml(historyItem.parts[0].text);

                    if(historyItem.parts[0].text !== "") {
                        this.chatBox.insertAdjacentHTML('beforeend', `<div class="message data-message">${html}</div>`);
                    }

                }else if (historyItem.parts[0].text.includes('[START-CHART]') && historyItem.parts[0].text.includes('[END-CHART]')) {

                    historyItem.parts[0].text = historyItem.parts[0].text.split('[START-CHART]')[1].split('[END-CHART]')[0];
                    
                    let chartData = JSON.parse(historyItem.parts[0].text);
                    
                    let { x_axis, y_axis, compare_by, table_data } = chartData;

                    let x_axis_heading = table_data[1][table_data[0].indexOf(x_axis)];
                    let x_axis_headings = x_axis.map(axis => table_data[1][table_data[0].indexOf(axis)]);
                    let y_axis_headings = y_axis.map(axis => table_data[1][table_data[0].indexOf(axis)]);
                    let compare_by_headings = compare_by.map(axis => table_data[1][table_data[0].indexOf(axis)]);

                    // if no x_axis_heading, use first x_axis_headings
                    if(!x_axis_heading){
                        x_axis_heading = x_axis_headings[0];
                    }

                    let compare_by_heading = compare_by_headings[0];

                    // generate unique ID for this chart
                    const chartId = Math.random().toString(36).substring(7);

                    this.chatBox.insertAdjacentHTML('beforeend', `<div class="message data-chart"><canvas id="chat-chart-${chartId}"></canvas>`);

                    setTimeout(() => {

                        // remove first row of table data (headers)
                        table_data.shift();

                        new CustomChart({
                            data: {
                                tableData: { data: table_data },
                                x_axis_heading: x_axis_heading,
                                x_axis_headings: x_axis_headings,
                                y_axis_headings: y_axis_headings,
                                compare_by_heading: compare_by_heading,
                            },
                            type: chartData.type,
                            elementId: `chat-chart-${chartId}`,
                        }).init();
                    }, 100);

                }else if(historyItem.parts[0].text.includes('[SYSTEM-USER]')){
                    this.chatBox.insertAdjacentHTML('beforeend', `<div class="message system-message-user">${historyItem.parts[0].text}</div>`);
                }else{
                    this.chatBox.insertAdjacentHTML('beforeend', `<div class="message user-message">${historyItem.parts[0].text}</div>`);
                }
            } else {
                const converter = new showdown.Converter({tables: true});
                let html = converter.makeHtml(historyItem.parts[0].text);
                
                // remove [SYSTEM-AI] from html
                html = html.replace('[SYSTEM-AI]', '');

                if(historyItem.parts[0].text.includes('[SYSTEM-AI]')){
                    this.chatBox.insertAdjacentHTML('beforeend', `<div class="message system-message-ai">${html}</div>`);
                }else {
                    this.chatBox.insertAdjacentHTML('beforeend', `<div class="message ai-message">${html}</div>`);
                }
            }
        });
        this.chatBox.scrollTop = this.chatBox.scrollHeight;
    }

    async getConversationHistory() {
        try {
            const response = await fetch(this.getConversationRoute, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer '+this.apiToken,
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content
                }
            });

            const data = await response.json();

            await this.insertNewMessages(data.new_messages);

            this.chatBox.scrollTop = this.chatBox.scrollHeight;
        } catch (error) {
            console.error("Error fetching conversation history:", error);
        }
    }
    
}
