import $ from 'jquery';
export default class DataExport {
    constructor(options) {
        this.columnOptions = options.columnOptions;
        this.filterOptions = options.filterOptions;
        this.dataRoute = options.dataRoute;
        this.timer = null;
        this.selectionData = null;
        if (options.selectionData) {
            this.selectionData = options.selectionData;
        }
        this.customColumns = [];
        this.unlimitedPreview = false;
        if(options.unlimitedPreview) {
            this.unlimitedPreview = options.unlimitedPreview;
        }
    }
    
    init() {
        let filterSelections = $('.filter-selections');
        let filterSelectionsContainer = $('.filter-selections-container');
        function toggleScrollClasses() {
            const isScrolledLeft = filterSelections.scrollLeft() === 0;
            const isScrolledRight = filterSelections.scrollLeft() + filterSelections.width() >= filterSelections.prop('scrollWidth');

            filterSelectionsContainer.toggleClass('not-scrolled-left', !isScrolledLeft);
            filterSelectionsContainer.toggleClass('not-scrolled-right', !isScrolledRight);
        }
        filterSelections.on('scroll', toggleScrollClasses);
        toggleScrollClasses();

        if(this.selectionData) {
            this.setSelectionData(this.selectionData);
            this.updateCumulativeColumns();
        }
        
        // if window.sortingColumns is not set, set it to an empty array
        if(!window.sortingColumns){
            window.sortingColumns = [];
        }
        
        $("select[name='group_by[]']").change((e) => {
            let firstCols = $(e.target).find('option:selected').map(function() {
                return $(this).data('first-col');
            }).get();
            let sortable = $('#columns-table tbody');
            for (let i = 0; i < firstCols.length; i++) {
                let row = sortable.find('input[name="columns[' + firstCols[i] + ']"]').parent().parent();
                $(row).parent().prepend(row);
                sortable.find('input[name="columns[' + firstCols[i] + ']"]').prop('checked', true);
            }
            sortable.trigger('sortupdate');
            setTimeout(() => {
                this.debouncedProcessChange();
            }, 100);
        });

        $("input[type='checkbox'], input[type='datetime-local'], input[name='decimal_places'], input[name='limit_results'], select:not([name='group_by[]'])").change((e) => {
            this.debouncedProcessChange();
        });

        $("#export-button").click((e) => {
            e.preventDefault();
            this.loadData(false);
            this.processChange();
        });
        
        this.processChange()
    }
    
    setSelectionData(data, customColumn = false, reference = null) {
        if (customColumn) {
            $('#customColumnFiltersDialog input[type="checkbox"]').prop('checked', false);
            if(reference){
                $('#customColumnFiltersDialog input[name="column_ref"]').val(reference);   
            }
        }else{
            $('input[type="checkbox"]').not('#customTableMainOptions input[type="checkbox"]').prop('checked', false);
        }
        
        let sortingColumns = [];
        
        // old sorting (single col)
        let sortingColumn = null;
        let sortingDirection = null;
        
        for (let key in data) {
            let value = data[key];
            if (Array.isArray(value) && key !== 'sorting_columns') {
                value.forEach((item) => {
                    let selector;
                    let checkbox;
                    
                    // replace _ with - in key
                    key = key.replace(/_/g, '-');

                    if (customColumn) {
                        selector = `input[name="custom-column-${key}[${item}]"]`;
                        checkbox = $(`#customColumnFiltersDialog`).find(selector);
                    } else {
                        selector = `input[name="${key}[${item}]"]`;
                        checkbox = $(selector);
                    }
                    
                    if (checkbox.length > 0) {
                        checkbox.prop('checked', true);
                    }
                });
                
                
                // this section is the for singular column sorting which is now deprecated, it can be removed in the future when all tables have been re-saved.
            } else if (key === 'sort_by_column') {
                sortingColumn = value;
            } else if(key === 'sort_by_direction') {
                sortingDirection = value;
                
                
            } else if(key === 'sorting_columns') {
                // for each, add to sortingColumns
                value.forEach((sort) => {
                    sortingColumns.push(sort);
                });
            }
        }

        // this section is the for singular column sorting which is now deprecated, it can be removed in the future when all tables have been re-saved.
        if(sortingColumn && sortingDirection){
            sortingColumns.push({
                'column': sortingColumn,
                'direction': sortingDirection
            });
        }
        
        window.sortingColumns = sortingColumns;

        if (!customColumn) {
            // order column options in the order they are in data.columns
            let sortable = $('#columns-table tbody');
            let columns = data.columns;
            for (let i = columns.length - 1; i >= 0; i--) {
                let row = sortable.find('input[name="columns[' + columns[i] + ']"]').parent().parent();
                $(row).parent().prepend(row);
            }
        }
        
        this.processChange();
    }

    getSelectionData(customColumn = false) {
        let prefix = '';
        if(customColumn){
            prefix = 'custom-column-';
        }
        
        let currentlySelectedObject;
        if(customColumn){
            currentlySelectedObject = {};
        }else{
            let columns = $('input[name^="columns"]:checked').filter(':not(:disabled)').map(function () {
                let column = $(this).attr('name').replace('columns[', '').replace(']', '');
                if(column !== 'columns-all') return column;
            }).get();
            
            // remove from window.sortingColumns where the column is not in columns or custom columns
            let self = this;
            window.sortingColumns = window.sortingColumns.filter((sort) => {

                if($('select[name="group_by_period"]').val() !== 'period'){
                    return columns.includes(sort.column) || self.customColumns.some((c) => c.reference === sort.column) || sort.column === 'period_date';
                }else{
                    return columns.includes(sort.column) || self.customColumns.some((c) => c.reference === sort.column);   
                }
            });
            
            currentlySelectedObject = {
                sorting_columns: window.sortingColumns,
                columns: columns,
            }
        }
        
        let filter = $(`input[name^="${prefix}filter"]:checked`).map(function () {
                return $(this).attr('name').replace(`${prefix}filter[`, '').replace(']', '');
            }).get();
        currentlySelectedObject.filter = filter;

        if(filter.includes('lines')){
            currentlySelectedObject.lines = $(`input[name^="${prefix}lines"]:checked`).map(function () {
                let line = $(this).attr('name').replace(`${prefix}lines[`, '').replace(']', '');
                if(line !== 'lines-all') return line;
            }).get();
        }

        if(filter.includes('users')){
            currentlySelectedObject.users = $(`input[name^="${prefix}users"]:checked`).map(function () {
                let user = $(this).attr('name').replace(`${prefix}users[`, '').replace(']', '');
                if (user !== 'users-all') return user;
            }).get();
        }

        if(filter.includes('products')){
            currentlySelectedObject.products = $(`input[name^="${prefix}products"]:checked`).map(function () {
                let product = $(this).attr('name').replace(`${prefix}products[`, '').replace(']', '');
                if(product !== 'products-all') return product;
            }).get();
            currentlySelectedObject.skus = $(`input[name^="${prefix}skus"]:checked`).map(function () {
                let sku = $(this).attr('name').replace(`${prefix}skus[`, '').replace(']', '');
                if(sku !== 'skus-all') return sku;
            }).get();
        }

        if(filter.includes('performance-loss-categories')){
            currentlySelectedObject.performance_loss_categories = $(`input[name^="${prefix}performance-loss-categories"]:checked`).map(function () {
                let category = $(this).attr('name').replace(`${prefix}performance-loss-categories[`, '').replace(']', '');
                if(category !== 'performance-loss-categories-all') return category;
            }).get();
        }

        if(filter.includes('downtime-types')){
            currentlySelectedObject.downtime_types = $(`input[name^="${prefix}downtime-types"]:checked`).map(function () {
                let type = $(this).attr('name').replace(`${prefix}downtime-types[`, '').replace(']', '');
                if(type !== 'downtime-types-all') return type;
            }).get();
        }
        
        if(filter.includes('downtime-production-stopped')){
            currentlySelectedObject.downtime_production_stopped = $(`input[name^="${prefix}downtime-production-stopped"]:checked`).map(function () {
                let type = $(this).attr('name').replace(`${prefix}downtime-production-stopped[`, '').replace(']', '');
                
                if(type === '1'){
                    type = true;
                }else if(type === '0'){
                    type = false;
                }
                
                if(type !== 'downtime-production-stopped-all') return type;
            }).get();
        }

        if(filter.includes('downtime-categories')){
            currentlySelectedObject.downtime_categories = $(`input[name^="${prefix}downtime-categories"]:checked`).map(function () {
                let category = $(this).attr('name').replace(`${prefix}downtime-categories[`, '').replace(']', '');
                if(category !== 'downtime-categories-all') return category;
            }).get();
        }

        if(filter.includes('machines')){
            currentlySelectedObject.machines = $(`input[name^="${prefix}machines"]:checked`).map(function () {
                let machine = $(this).attr('name').replace(`${prefix}machines[`, '').replace(']', '');
                if(machine !== 'machines-all') return machine;
            }).get();
        }

        if(filter.includes('waste-products')){
            currentlySelectedObject.waste_products = $(`input[name^="${prefix}waste-products"]:checked`).map(function () {
                let product = $(this).attr('name').replace(`${prefix}waste-products[`, '').replace(']', '');
                if(product !== 'waste-products-all') return product;
            }).get();
            currentlySelectedObject.waste_skus = $(`input[name^="${prefix}waste-skus"]:checked`).map(function () {
                let sku = $(this).attr('name').replace(`${prefix}waste-skus[`, '').replace(']', '');
                if(sku !== 'waste-skus-all') return sku;
            }).get();
        }

        if(filter.includes('waste-defects')){
            currentlySelectedObject.waste_defects = $(`input[name^="${prefix}waste-defects"]:checked`).map(function () {
                let defect = $(this).attr('name').replace(`${prefix}waste-defects[`, '').replace(']', '');
                if(defect !== 'waste-defects-all') return defect;
            }).get();
        }

        if(filter.includes('waste-locations')){
            currentlySelectedObject.waste_locations = $(`input[name^="${prefix}waste-locations"]:checked`).map(function () {
                let location = $(this).attr('name').replace(`${prefix}waste-locations[`, '').replace(']', '');
                if(location !== 'waste-locations-all') return location;
            }).get();
        }

        if(filter.includes('shifts')){
            currentlySelectedObject.shifts = $(`input[name^="${prefix}shifts"]:checked`).map(function () {
                let shift = $(this).attr('name').replace(`${prefix}shifts[`, '').replace(']', '');
                if(shift !== 'shifts-all') return shift;
            }).get();
        }
        
        return currentlySelectedObject;
    }
    
    setCustomColumnsSelectionData(customColumns) {
        if(customColumns) {
            customColumns.forEach((customColumn) => {
                this.customColumns.push(customColumn);
            });
            this.processChange();
        }
    }

    getCustomColumnsSelectionData() {
        return this.customColumns;
    }
    
    addCustomColumn(reference, title, formula, selectionData){
        let customColumn = {
            reference: reference,
            title: title,
            formula: formula,
            selectionData: selectionData
        };
        
        this.customColumns.push(customColumn);
        this.processChange();
    }
    
    updateCustomColumn(reference, title=null, formula=null, selectionData=null){
        let customColumnIndex = this.customColumns.findIndex((c) => c.reference === reference);
        
        if (customColumnIndex !== -1) {
            if (title !== null) this.customColumns[customColumnIndex].title = title;
            
            if (formula !== null) this.customColumns[customColumnIndex].formula = formula;

            if (selectionData !== null) this.customColumns[customColumnIndex].selectionData = selectionData;
        } else {
            console.warn(`Custom column with reference ${reference} not found.`);
        }
        
        this.processChange();
    }
    
    debounce(func, delay) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            func.apply(this);
        }, delay);
    }

    loadData (preview) {
        let phrases = [
            'Requesting data...',
            'Processing data...',
            'Crunching numbers...'
        ];

        let phraseIndex = 0;
        let intervalId;

        let updatePhrase = function() {
            let loadingPhrase = document.getElementById('loadingPhrase');
            
            if(loadingPhrase){
                if (phraseIndex === 0) {
                    loadingPhrase.innerHTML = phrases[phraseIndex];
                    phraseIndex++;
                } else {
                    loadingPhrase.innerHTML = phrases[phraseIndex];
                    phraseIndex = (phraseIndex === 1) ? 2 : 1;
                }   
            }
        };

        intervalId = setInterval(updatePhrase, 2000);

        let data= this.getSelectionData();
        data.customColumns = this.getCustomColumnsSelectionData();
        data._token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        data.preview = preview;
        data.unlimitedPreview = this.unlimitedPreview;
        data.date_from = $("input[name='date_from']").val();
        data.date_to = $("input[name='date_to']").val();
        data.relative_start_date = $("select[name='relative_start_date']").val();
        data.relative_end_date = $("select[name='relative_end_date']").val();
        
        if($("select[name='group_by[]']").val().length === 0){
            data.group_by = ['oee_data.id'];
        }else{
            data.group_by = $("select[name='group_by[]']").val();
        }
        
        data.group_by_period = $("select[name='group_by_period']").val();
        data.measurement_unit = $("select[name='measurement_unit']").val();
        if($("select[name='cumulative_column']").val() !== '') {
            data.cumulative_column = $("select[name='cumulative_column']").val();
        }

        if($("input[name='decimal_places']").length && $("input[name='decimal_places']").val() !== ''){
            data.decimal_places = $("input[name='decimal_places']").val();
        }
        
        if($("input[name='limit_results']").length && $("input[name='limit_results']").val() !== ''){
            data.limit_results = $("input[name='limit_results']").val();
        }
        
        if($("input[name='remove_zero_results']").length && $("input[name='remove_zero_results']").is(":checked")){
            data.remove_zero_results = true;
        }
        
        if($("input[name='add_totals_row']").length && $("input[name='add_totals_row']").is(":checked")){
            data.add_totals_row = true;
        }
        
        if(preview) {
            fetch(this.dataRoute, {
                method: 'post',
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }).then(async (response) => {
                let previewData = await response.json();
                document.getElementById("preview").innerHTML = '';

                clearInterval(intervalId);
                
                if(previewData.data.length > 500){
                    previewData.data = previewData.data.slice(0, 501);
                    
                    document.getElementById("preview").insertAdjacentHTML('beforebegin', `<div class="results-count red">The data returned has many rows which means it is unlikely to be suitable for use with charts. We suggest grouping by a time period or another grouping option to reduce the number of rows.<br></div>`);
                    document.getElementById("preview").insertAdjacentHTML('beforebegin', `<div class="results-count">Showing 500 of <strong>${previewData.count}</strong> rows returned.<br></div>`);
                }else{
                    document.getElementById("preview").insertAdjacentHTML('beforebegin', `<div class="results-count">Showing ${previewData.data.length - 1} of <strong>${previewData.count}</strong> rows returned.<br></div>`);
                }
                
                for (let i = 0; i < previewData.data.length; i++) {
                    let row = $('<tr/>');

                    // skip the first row - IDs
                    if(i === 0) continue;
                    
                    for (let x = 0; x < previewData.data[i].length; x++) {
                        
                        let cellValue = previewData.data[i][x];
                        if (cellValue == null) cellValue = "";
                        
                        if (i === 1) {
                            // headers
                            
                            let thElement = $('<th/>').html(cellValue)
                            thElement.click((e) => {
                                
                                let columnId = previewData.data[0][x];
                                
                                // if columnId is in sortingColumns[].column, get the direction
                                let sortColumn = window.sortingColumns.find((sort) => sort.column === columnId);
                                if(sortColumn){
                                    if(sortColumn.direction === 'asc'){
                                        // replace it with desc in window.sortingColumns
                                        sortColumn.direction = 'desc';
                                    }else{
                                        // remove this sort
                                        window.sortingColumns = window.sortingColumns.filter((sort) => sort.column !== columnId);
                                    }
                                }else{
                                    // add it to window.sortingColumns with direction asc
                                    window.sortingColumns.push({
                                        column: columnId,
                                        direction: 'asc'
                                    });
                                }
                                
                                this.processChange();
                            });
                            
                            // find if there is an element in window.sortingColumns with column === previewData.data[0][x]
                            let sortColumn = window.sortingColumns.find((sort) => sort.column === previewData.data[0][x]);
                            if(sortColumn){
                                thElement.attr('data-dir', sortColumn.direction);
                                thElement.attr('data-order', window.sortingColumns.indexOf(sortColumn) + 1);
                            }
                            
                            row.append(thElement);
                        } else {
                            
                            
                            row.append($('<td/>').html(cellValue));
                        }
                    }
                    $(document.getElementById("preview")).append(row);
                }

                new TdTooltip('#preview');
            });
        }else{
            delete data.preview;
            fetch(this.dataRoute, {
                method: 'post',
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Accept: "text/csv",
                },
            }).then(res => res.blob()).then(blob => {
                clearInterval(intervalId);
                let file = window.URL.createObjectURL(blob);
                window.location.assign(file);
            });
        }
    }

    updateCumulativeColumns() {
        let cumulativeColumnSelect = document.querySelector('select[name="cumulative_column"]');
        if (cumulativeColumnSelect) {
            let currentValue = cumulativeColumnSelect.value;

            cumulativeColumnSelect.innerHTML = '';
            let selectedColumns = $('tr[data-numerical] input[name^="columns"]:checked').map(function () {
                let column = $(this).attr('name').replace('columns[', '').replace(']', '');
                if (column !== 'columns-all') return column;
            }).get();

            // Add none option
            let noneOption = document.createElement('option');
            noneOption.value = '';
            noneOption.text = 'None';
            cumulativeColumnSelect.add(noneOption);

            // Add updated column options
            selectedColumns.forEach((column) => {
                let option = document.createElement('option');
                option.value = column;
                option.text = this.columnOptions.find((option) => option.id === column).name;
                cumulativeColumnSelect.add(option);
            });

            // Add custom columns
            this.customColumns.forEach((column) => {
                let option = document.createElement('option');
                option.value = column.reference;
                option.text = column.title;
                cumulativeColumnSelect.add(option);
            });

            let newValue = Array.from(cumulativeColumnSelect.options).find(option => option.value === currentValue);
            if (newValue) {
                cumulativeColumnSelect.value = currentValue;
            } else {
                cumulativeColumnSelect.value = '';
            }
        }
    }

    debouncedLoadData(){
        $('.results-count').remove();
        
        this.debounce(function(){
            return this.loadData(true);
        }, 1500);
    }

    debouncedProcessChange(){
        
        this.updateCumulativeColumns();
        
        this.debounce(function(){
            return this.processChange();
        }, 700);   
    }

    processChange() {
        let filterOptions = this.filterOptions;

        this.updateCumulativeColumns();
        
        document.getElementById("preview").innerHTML = '<tr><td>Please select some columns.</td></tr>';
        
        $('#custom-columns-table tbody').html('');
        if(this.customColumns.length > 0){
            
            // add columns to table
            this.customColumns.forEach((column) => {
                let row = $('<tr/>');
                row.append($('<td/>').addClass('clickable-reference').html('['+column.reference+']'));
                row.append($('<td/>').html(column.title));
                row.append($('<td/>').html(column.formula));
                
                let filters = column.selectionData.filter;
                let filtersString = '';
                let editOrAdd = 'Add';
                if (filters.length > 0) {
                    filters = filters.map((filter) => {
                        let filterOption = filterOptions.find((option) => option.id === filter);
                        if(filterOption){
                            return filterOption.name;
                        }
                    });
                    
                    filtersString = 'Filters Set: ' + filters.join(', ');
                    editOrAdd = 'Edit';
                }

                let filtersTextNode = $('<span/>').text(filtersString + ' ');

                let editAddFiltersDetail = $('<button/>').addClass('btn btn-primary').html(editOrAdd + ' Filters').click((e) => {
                    e.preventDefault();
                    let customColumnFiltersDialog = document.querySelector('#customColumnFiltersDialog');
                    customColumnFiltersDialog.showModal();
                    this.setSelectionData(column.selectionData, true, column.reference);
                });

                row.append($('<td/>').append(filtersTextNode).append(editAddFiltersDetail));
                
                // delete button
                let deleteButton = $('<button/>').addClass('btn btn-primary').html('Delete');
                deleteButton.click((e) => {
                    e.preventDefault();
                    if(confirm('Are you sure you want to delete this custom column?')){
                        this.customColumns = this.customColumns.filter((c) => c.reference !== column.reference);
                        this.processChange();
                    }
                });
                row.append($('<td/>').append(deleteButton));
                
                // edit button
                let editButton = $('<button/>').addClass('btn btn-primary').html('Edit');
                editButton.click((e) => {
                    e.preventDefault();
                    // replace reference, title, and formula tds with text inputs
                    let referenceInput = $('<input/>').attr('type', 'text').attr('disabled', true).val(column.reference);
                    let titleInput = $('<input/>').attr('type', 'text').val(column.title);
                    let formulaInput = $('<input/>').attr('type', 'text').val(column.formula);
                    
                    row.children().eq(0).html(referenceInput);
                    row.children().eq(1).html(titleInput);
                    row.children().eq(2).html(formulaInput);
                    
                    // change the text of editButton to 'Save'
                    editButton.html('Save');
                    
                    // disable the add filters button
                    editAddFiltersDetail.prop('disabled', true);
                    
                    // change the click event of editButton to save the changes
                    editButton.off('click');
                    editButton.click((e) => {
                        e.preventDefault();
                        
                        let newTitle;
                        let newFormula;
                        
                        // if title has changed, check if the new title is unique
                        if(column.title !== titleInput.val()){
                            newTitle = this.validateCustomColumnTitle(titleInput.val());
                            
                            if(newTitle === false){
                                return;
                            }
                        }
                        
                        // if formula has changed, check if the new formula is valid
                        if(column.formula !== formulaInput.val()){
                            newFormula = this.validateCustomColumnFormula(formulaInput.val(), column.reference);
                            if(newFormula === false){
                                return;
                            }
                        }
                        
                        // enable the add filters button
                        editAddFiltersDetail.prop('disabled', false);
                        
                        this.updateCustomColumn(column.reference, newTitle, newFormula);
                    });
                    
                });
                row.append($('<td/>').append(editButton));
                
                $('#custom-columns-table tbody').append(row);
            });
        }

        ['filter', 'custom-column-filter'].forEach((filterLocation) => {

            let customColumnPrefix = '';
            if(filterLocation === 'custom-column-filter') {
                // this is the prefix used on the div IDs for each filter type
                customColumnPrefix = 'custom-column-';
            }

            $(`input[name^='${filterLocation}']`).map(function(){
                // filterType is the name of the filter type, e.g. lines, machines, products, etc.
                let filterType = $(this).attr('name').replace(`${filterLocation}[`, '').replace(']', '');
                
                if($(this).is(":checked")){
                    if(filterType === 'products'){
                        $(`#${customColumnPrefix}skusTableContainer`).fadeIn('fast');
                    }
                    if(filterType === 'waste-products'){
                        $(`#${customColumnPrefix}waste-skusTableContainer`).fadeIn('fast');
                    }
                    $(`#${customColumnPrefix}${filterType}TableContainer`).fadeIn('fast');
                }else{
                    if(filterType === 'products'){
                        $(`input[name^='${customColumnPrefix}skus']`).prop("checked", true);
                        $(`#${customColumnPrefix}skusTableContainer`).fadeOut('fast');
                    }
                    if(filterType === 'waste-products'){
                        $(`input[name^='${customColumnPrefix}waste-skus']`).prop("checked", true);
                        $(`#${customColumnPrefix}waste-skusTableContainer`).fadeOut('fast');
                    }
                    $(`#${customColumnPrefix}${filterType}TableContainer input`).prop("checked", true);
                    $(`#${customColumnPrefix}${filterType}TableContainer`).fadeOut('fast');
                }
            });

            $(`#${customColumnPrefix}skusTableContainer table tr`).hide();
            $(`input[name^="${customColumnPrefix}products"]:checked`).map(function () {
                let product_id = $(this).attr('name').replace(`${customColumnPrefix}products[`, '').replace(']', '');
                $(`#${customColumnPrefix}skusTableContainer table tr[data-product-id="${product_id}"]`).show();
            });

            $(`#${customColumnPrefix}waste-skusTableContainer table tr`).hide();
            $(`input[name^="${customColumnPrefix}waste-products"]:checked`).map(function () {
                let product_id = $(this).attr('name').replace(`${customColumnPrefix}waste-products[`, '').replace(']', '');
                $(`#${customColumnPrefix}waste-skusTableContainer table tr[data-product-id="${product_id}"]`).show();
            });

            $(`#${customColumnPrefix}machinesTableContainer table tr`).hide();
            $(`input[name^="${customColumnPrefix}lines"]:checked`).map(function () {
                let line_id = $(this).attr('name').replace(`${customColumnPrefix}lines[`, '').replace(']', '');
                $(`#${customColumnPrefix}machinesTableContainer table tr[data-line-id="${line_id}"]`).show();
            });

            $(`#${customColumnPrefix}waste-locationsTableContainer table tr`).hide();
            $(`input[name^="${customColumnPrefix}lines"]:checked`).map(function () {
                let line_id = $(this).attr('name').replace(`${customColumnPrefix}lines[`, '').replace(']', '');
                $(`#${customColumnPrefix}waste-locationsTableContainer table tr[data-line-id="${line_id}"]`).show();
            });

            $(`#${customColumnPrefix}performance-loss-categoriesTableContainer table tr`).hide();
            $(`input[name^="${customColumnPrefix}lines"]:checked`).map(function () {
                let line_id = $(this).attr('name').replace(`${customColumnPrefix}lines[`, '').replace(']', '');
                $(`#${customColumnPrefix}performance-loss-categoriesTableContainer table tr[data-line-id="${line_id}"]`).show();
            });

            $(`#${customColumnPrefix}downtime-categoriesTableContainer table tr`).hide();

            let linesToDisplay = $(`input[name^="${customColumnPrefix}lines"]:checked`).map(function () {
                return $(this).attr('name').replace(`${customColumnPrefix}lines[`, '').replace(']', '');
            }).get();

            let machinesToDisplay = $(`input[name^="${customColumnPrefix}machines"]:checked`).map(function () {
                return $(this).attr('name').replace(`${customColumnPrefix}machines[`, '').replace(']', '');
            }).get();

            let downtimeTypesToDisplay = $(`input[name^="${customColumnPrefix}downtime-types"]:checked`).map(function () {
                return $(this).attr('name').replace(`${customColumnPrefix}downtime-types[`, '').replace(']', '');
            }).get();

            let filterByLine = $(`input[name='${filterLocation}[lines]']`).is(":checked");
            let filterByMachine = $(`input[name='${filterLocation}[machines]']`).is(":checked");
            let filterByDowntimeType = $(`input[name='${filterLocation}[downtime-types]']`).is(":checked");

            $(`#${customColumnPrefix}downtime-categoriesTableContainer table tr`).filter(function() {
                let $row = $(this);
                let lineId = $row.data('line-id').toString();
                let machineId = $row.data('machine-id') !== undefined ? $row.data('machine-id').toString() : null;
                let downtimeTypeId = $row.data('downtime-type-id').toString();

                let showRow = true;

                if (filterByLine && !linesToDisplay.includes(lineId)) {
                    showRow = false;
                }

                if (filterByMachine && !machinesToDisplay.includes(machineId)) {
                    showRow = false;
                }
                if (filterByDowntimeType && !downtimeTypesToDisplay.includes(downtimeTypeId)) {
                    showRow = false;
                }

                return showRow;
            }).show();
        });

        $('[name]').filter(function() {
            return $(this).attr('name').startsWith('columns[');
        }).prop('disabled', false);

        let selectedGroupBy = $('select[name="group_by[]"] option:selected').map((_, el) => $(el).val()).get();

        this.columnOptions.forEach(function(option) {
            if (!option['enable_oee_cols_when_grouped_by']) {
                if (selectedGroupBy.includes(option['group_by'])) {
                    $('input[name="columns[oee_data.a]"]').prop('disabled', true);
                    $('input[name="columns[oee_data.p]"]').prop('disabled', true);
                    $('input[name="columns[oee_data.q]"]').prop('disabled', true);
                    $('input[name="columns[oee_data.oee]"]').prop('disabled', true);
                }
            }
        });

        this.filterOptions.forEach(function(option) {
            if (!option['enable_oee_cols_when_filtered_by']) {
                if($('input[name="filter[' + option['id'] + ']').is(":checked")){
                    $('input[name="columns[oee_data.a]"]').prop('disabled', true);
                    $('input[name="columns[oee_data.p]"]').prop('disabled', true);
                    $('input[name="columns[oee_data.q]"]').prop('disabled', true);
                    $('input[name="columns[oee_data.oee]"]').prop('disabled', true);
                }
            }
        });

        if($('select[name="group_by_period"]').val() !== 'period'){
            $('input[name="columns[period_date]"]').prop('disabled', false);
        }else{
            $('input[name="columns[period_date]"]').prop('disabled', true);
        }

        if($("input[name^='columns']").is(":checked")) {
            document.getElementById("preview").innerHTML = '<tr class="no-table-data"><td><div class="spinner"></div> <span id="loadingPhrase">Preparing Request...</span></td></tr>';
            this.debouncedLoadData();
        }
    }
    
    validateCustomColumnReference(reference){
        // strip preceding and trailing whitespace from reference
        reference = reference.trim();

        // check if reference only includes A-Za-Z0-9 and dash or underscore
        if(!/^[a-zA-Z0-9_-]+$/.test(reference)){
            alert('Reference can only include A-Z, a-z, 0-9, and dash or underscore.');
            return false;
        }

        // check if reference has already been used
        for(let i = 0; i < this.customColumns.length; i++){
            if(this.customColumns[i].reference === reference){
                alert('Reference has already been used.');
                return false;
            }
        }

        // check if reference is a built-in column
        for(let i = 0; i < this.columnOptions.length; i++){
            if(this.columnOptions[i].id === reference){
                alert('Reference cannot be a built-in column.');
                return false;
            }
        }
        
        return reference;
    }
    
    validateCustomColumnTitle(title){
        // strip preceding and trailing whitespace from title
        title = title.trim();

        // check if title is empty
        if(title === ''){
            alert('Title cannot be empty.');
            return false;
        }
        
        // check if title has already been used
        for(let i = 0; i < this.customColumns.length; i++){
            if(this.customColumns[i].title === title){
                alert('Title has already been used.');
                return false;
            }
        }
        
        // check if title matches a built-in column title
        for(let i = 0; i < this.columnOptions.length; i++){
            if(this.columnOptions[i].name === title){
                alert('Title cannot match a built-in column title.');
                return false;
            }
        }
        
        return title;
    }
    
    validateCustomColumnFormula(formula, currentReference=null){
        // strip preceding and trailing whitespace from formula
        formula = formula.trim();

        // check if formula is empty
        if(formula === ''){
            alert('Formula cannot be empty.');
            return false;
        }
        
        // check if all references in [] are valid
        let references = formula.match(/\[(.*?)\]/g);
        if(references){
            for(let i = 0; i < references.length; i++){
                let reference = references[i].replace('[', '').replace(']', '');
                let validReference = false;
                
                // check against custom columns
                for(let j = 0; j < this.customColumns.length; j++){
                    // if this reference is the custom column being edited, stop
                    if(currentReference && this.customColumns[j].reference === currentReference){
                        break;
                    }

                    if(this.customColumns[j].reference === reference){
                        validReference = true;
                        break;
                    }
                }
                
                // check against built-in columns
                if(!validReference){
                    for(let j = 0; j < this.columnOptions.length; j++){
                        if(this.columnOptions[j].id === reference){
                            validReference = true;
                            break;
                        }
                    }
                }
                
                if(!validReference){
                    alert('Invalid reference in formula. Check all references match a built-in column reference or custom column appearing before this one.');
                    return false;
                }
            }
            
            // all references are valid
            // replace all [] with 10 to check the formula is a valid expression
            let formulaToTest = formula.replace(/\[(.*?)\]/g, '10');

            formulaToTest = formulaToTest.replace(/\s+/g, ' ');
            formulaToTest = formulaToTest.replace(/\+-/g, '-');
            formulaToTest = formulaToTest.replace(/-\+/g, '-');
            formulaToTest = formulaToTest.replace(/\+ -/g, '-');
            formulaToTest = formulaToTest.replace(/- \+/g, '-');
            formulaToTest = formulaToTest.replace(/--/g, '+');
            formulaToTest = formulaToTest.replace(/- -/g, '+');
            
            if(!this.isValidExpression(formulaToTest)) {
                alert('Invalid formula. Check the formula is a valid expression.');
                return false;
            }
        }
        
        return formula;
    }

    isValidExpression(expression) {
        const validCharacters = /^[0-9+\-*/().\s]*$/;

        if (!validCharacters.test(expression)) {
            console.log("invalid characters")
            return false;
        }
        
        try {
            const result = mathEvaluate(expression);
            console.log(result)
            return !isNaN(result) && isFinite(result);
        } catch (e) {
            console.log(e)
            return false;
        }
    }
}
