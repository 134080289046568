export default class CustomChart {
    constructor(options) {
        this.data = options.data.tableData.data;
        this.x_axis_heading = options.data.x_axis_heading;
        this.x_axis_headings = options.data.x_axis_headings;
        this.y_axis_headings = options.data.y_axis_headings;
        this.compare_by_heading = options.data.compare_by_heading;
        this.type = options.type;
        this.elementId = options.elementId;
        this.stacked = false;
        
        this.index_axis = 'x';
        if(this.type === 'horizontalBar'){
            this.type = 'bar';
            this.index_axis = 'y';
        }
        
        if(this.type === 'stackedBar'){
            this.type = 'bar';
            this.stacked = true;
        }
    }

    getColor(index) {
        // Define your color palette
        const colors = [
            'rgba(255, 99, 132, 0.6)', // Red
            'rgba(54, 162, 235, 0.6)', // Blue
            'rgba(75, 192, 192, 0.6)', // Green
            'rgba(255, 206, 86, 0.6)', // Yellow
            'rgba(153, 102, 255, 0.6)', // Purple
            'rgba(255, 159, 64, 0.6)'  // Orange
        ];

        // Return a color based on the index of the dataset
        return colors[index % colors.length];
    }

    init() {
        let xAxisHeading = this.x_axis_heading;
        let xAxisHeadings = this.x_axis_headings;
        let yAxisHeadings = this.y_axis_headings;
        let compareByHeading = this.compare_by_heading;

        // Find the indices of the headings in the first row of this.data
        
        let headers = this.data[0];
        let xIndex = headers.indexOf(xAxisHeading);
        let xIndices = xAxisHeadings.map(heading => headers.indexOf(heading));
        let compareByIndex = headers.indexOf(compareByHeading);
        let yIndices = yAxisHeadings.map(heading => headers.indexOf(heading));

        if (this.type === 'line' || this.type === 'bar') {
            let groupedData = {};

            // Group data by the compareByHeading and yAxisHeadings columns
            for (let i = 1; i < this.data.length; i++) {
                let row = this.data[i];
                let compareByValue = compareByIndex !== -1 ? row[compareByIndex] : null;
                let xValue = row[xIndex];

                for (let yIndex of yIndices) {
                    let yHeading = headers[yIndex];
                    let yValue = parseFloat(row[yIndex]);

                    let key = compareByValue ? `${compareByValue} (${yHeading})` : yHeading;

                    if (!groupedData[key]) {
                        groupedData[key] = {
                            label: key,
                            data: []
                        };
                    }

                    groupedData[key].data.push(this.index_axis === 'x' ? { x: xValue, y: yValue } : { x: yValue, y: xValue });
                }
            }
            
            let labels = this.data.slice(1).map(row => row[xIndex]).filter((value, index, self) => self.indexOf(value) === index)
            
            // Create datasets from grouped data
            let datasets = Object.values(groupedData).map(group => ({
                label: group.label,
                data: group.data,
                fill: false,
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
                trendline: false,
                yAxisID: 'y',
            }));

            let canvas = document.getElementById(this.elementId);
            let ctx = canvas.getContext('2d');

            if (window.charts === undefined) {
                window.charts = {};
            }

            let maxDataValue;
            if(this.index_axis === 'x') {
                maxDataValue = Math.max(...datasets.flatMap(dataset => dataset.data.map(point => point.y))) * 1.1;
            }else{
                maxDataValue = Math.max(...datasets.flatMap(dataset => dataset.data.map(point => point.x))) * 1.1;
            }
            let indexAxis = this.index_axis;
            
            let chartType = this.type;
            let isStacked = this.stacked;
            
            window.charts[this.elementId] = new Chart(ctx, {
                type: this.type,
                data: {
                    labels: labels,
                    datasets: datasets.map((dataset, index) => ({
                        ...dataset,
                        backgroundColor: this.getColor(index) // Assign a distinct color for each dataset
                    }))
                },
                options: {
                    indexAxis: this.index_axis,
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            type: this.index_axis === 'x' ? 'linear' : 'category',
                            stacked: this.stacked,
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: this.index_axis === 'x' ? yAxisHeadings.join(', ') : xAxisHeading
                            },
                            suggestedMax: maxDataValue
                        },
                        x: {
                            type: this.index_axis === 'x' ? 'category' : 'linear',
                            stacked: this.stacked,
                            title: {
                                display: true,
                                text: this.index_axis === 'x' ? xAxisHeading : yAxisHeadings.join(', ')
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: true
                        },
                        trendline: {},
                        datalabels: {
                            display: true,
                            align: 'end',
                            anchor: 'end',
                            formatter: function(value, context) {
                                if(chartType === 'bar' && isStacked === false){
                                    if(indexAxis === 'x') {
                                        return value.y;
                                    }else{
                                        return value.x;
                                    }
                                }else{
                                    return '';
                                }
                            }
                        }
                    }
                },
                plugins: [window.ChartDataLabels]
            });
        }else if(this.type === 'waterfall'){

            let headers = this.data[0];
            let bars = this.data[1];
            let labels = xAxisHeadings;

            let cumulative = 0;

            let waterfallData = xAxisHeadings.map(xHeading => {
                let index = headers.indexOf(xHeading);
                if (index !== -1) {
                    let currentValue = parseFloat(bars[index]);
                    let startValue = cumulative; // Start from the current cumulative value

                    if (currentValue >= 0) {
                        // Positive bars start from 0
                        cumulative = currentValue; // Update cumulative with positive value
                        return [0, cumulative];
                    } else {
                        // Negative bars start from cumulative
                        cumulative += currentValue; // Update cumulative with negative value
                        return [startValue, cumulative];
                    }
                } else {
                    return [0, 0]; // Handle missing data
                }
            });

            let datasets = [{
                label: '',
                data: waterfallData,
                fill: false,
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
                trendline: false,
                yAxisID: 'y',
                backgroundColor: waterfallData.map(values => {
                    return values[1] < values[0] ? 'rgba(255, 99, 132, 0.7)' : 'rgba(0, 123, 255, 0.7)'; // Red for negative, blue for positive
                }),
                borderColor: waterfallData.map(values => {
                    return values[1] < values[0] ? 'rgba(255, 99, 132, 1)' : 'rgba(0, 123, 255, 1)'; // Matching border color
                }),
                borderWidth: 1,
            }];

            let canvas = document.getElementById(this.elementId);
            let ctx = canvas.getContext('2d');

            if (window.charts === undefined) {
                window.charts = {};
            }

            window.charts[this.elementId] = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: datasets
                },
                options: {
                    indexAxis: 'x',
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            type: 'linear',
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: 'Percentage (%)'
                            },
                            ticks: {
                                callback: function(value) {
                                    if (value === 120) {
                                        return ''; // Return an empty string to hide the 120 tick
                                    }
                                    return value + '%';
                                }
                            },
                            max: 120
                        },
                        x: {
                            type: 'category',
                        }
                    },
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    let start = context.raw[0];
                                    let end = context.raw[1];
                                    let value = end - start;
                                    return `${context.label}: ${value.toFixed(2)}%`; // Display the actual value
                                }
                            }
                        },
                        datalabels: {
                            display: true,
                            align: 'end',
                            anchor: 'end',
                            formatter: function(value, context) {
                                let start = value[0];
                                let end = value[1];
                                let percentage = (end - start).toFixed(2);
                                return `${percentage}%`; // Show percentage above each bar
                            }
                        }
                    }
                },
                plugins: [window.ChartDataLabels]
            });



        }else if (this.type === 'pie' || this.type === 'doughnut') {
            let pieLabels = [];
            let pieData = [];

            if (compareByIndex !== -1) {
                // Use the compare_by_heading values as segments
                let aggregatedData = {};

                for (let i = 1; i < this.data.length; i++) {
                    let row = this.data[i];
                    let label = row[compareByIndex];
                    
                    // value is 1st Y-axis column position
                    let value = parseFloat(row[yIndices[0]]);

                    if (!aggregatedData[label]) {
                        aggregatedData[label] = 0;
                    }
                    aggregatedData[label] += value;
                }

                for (let label in aggregatedData) {
                    pieLabels.push(label);
                    pieData.push(aggregatedData[label]);
                }
            } else {
                // Use the Y-axis column values as segments
                for (let yIndex of yIndices) {
                    let label = headers[yIndex];
                    let total = 0;

                    for (let i = 1; i < this.data.length; i++) {
                        let row = this.data[i];
                        total += parseFloat(row[yIndex]);
                    }

                    pieLabels.push(label);
                    pieData.push(total);
                }
            }

            let canvas = document.getElementById(this.elementId);
            let ctx = canvas.getContext('2d');

            if (window.charts === undefined) {
                window.charts = {};
            }
            
            // if pieData has only 1 value, make another segment with complementary value
            if(pieData.length === 1){
                pieData.push(100 - pieData[0]);
                pieLabels.push('Away from 100%');
            }
            
            window.charts[this.elementId] = new Chart(ctx, {
                type: this.type,
                data: {
                    labels: pieLabels,
                    datasets: [{
                        data: pieData,
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true
                        }
                    }
                }
            });
        }
    }
}
