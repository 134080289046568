import $ from 'jquery';
export default class SignOffForm {

    constructor() {

        this.$form = $('#sign-off-form');

        const protocol = window.location.protocol;
        const hostname = window.location.hostname;

        this.machineDowntimeApi = `${protocol}//${hostname}/api/machine/downtime`;

        this.registerEvents();

    }
     
    async loadMachineDowntimes(machine) {

        let $select = $(machine).closest('fieldset').find('.input-group-downtime-category select');

        await $.get(this.machineDowntimeApi, {
            'machine_id': machine.value
        }).then(downtimes => {
            $select.empty();

            if (Array.isArray(downtimes) && downtimes.length === 0) {
                alert('There are no downtime categories associated with this machine.\r\nYou can add downtime categories via the Admin module.');
            } else {
                $select.append(`<option value="" selected disabled>Please select a downtime category</option>`);
                for (var downtimeCategory of downtimes) {
                    let option = `<option value="${downtimeCategory.id}">${downtimeCategory.name}</option>`;
                    $select.append(option);
                }
            }
        });

    }

    toggleUpdateBtn() {

        if(this.$form.find('.sign-off-card input[type="radio"][value="true"]:checked').length > 0)
        {
            $('.update-all-btn').removeClass('hidden');
        }
        else
        {
            $('.update-all-btn').addClass('hidden');
        }
        
    }

    registerEvents() {

        // change: machine
        this.$form.on('change', '.input-group-machine select', e => {
            this.loadMachineDowntimes(e.target);
        });

        // change: sign off button
        this.$form.on('click', 'button[name="sign_off"]', e => {

            // get elements as variables
            let $this = $(e.target);
            let radioBtns = $this.siblings('input[type="radio"]');
            let falseBtn = radioBtns.first();
            let trueBtn = radioBtns.last();

            // uncheck all radios
            radioBtns.attr('checked', false);

            if ($this.hasClass('btn-green'))
            {
                $this.addClass('btn-red');
                $this.removeClass('btn-green');

                falseBtn.attr('checked', true);
            }
            else
            {
                $this.addClass('btn-green');
                $this.removeClass('btn-red');
                
                trueBtn.attr('checked', true);
            }

            // toggle update button
            this.toggleUpdateBtn();

        });

    }

}
