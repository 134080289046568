import $ from 'jquery';
export default class Autoscroll {

    constructor(duration, delay, refreshDuration = false) {

        this.active = false;

        this.body = $('.app .view');
        this.trigger = $('.autoscroll');

        this.scroll = {
            handler: null,
            duration: duration,
            delay: delay
        };
        
        if(refreshDuration)
        {
            this.refresh = {
                handler: null,
                duration: refreshDuration
            };
        }
        else
        {
            this.refresh = null;
        }

        this.registerEvents();

    }

    startRefreshTimer() {

        if(this.refresh)
        {
            this.refresh.handler = setTimeout(function() {
                window.location.reload();
            }, this.refresh.duration);
        }

    }

    cancelRefreshTimer() {

        if(this.refresh) clearTimeout(this.refresh.handler);

    }

    start() {

        this.startRefreshTimer();

        this.scroll.handler = setInterval(e => {

            // set scroll to bottom of screen minus viewport height
            this.body.animate({
                scrollTop: this.body[0].scrollHeight - this.body.height()
            }, this.scroll.duration, e => {
    
                // add delay before scrolling back up, pause at bottom of screen
                setTimeout(e => {
                
                    // scroll back up the page
                    this.body.animate({
                        scrollTop: 0
                    }, this.scroll.duration);
    
                }, this.scroll.delay);
            });

        }, (this.scroll.duration + this.scroll.delay * 2));

        this.active = true;
        sessionStorage.setItem('autoscrolling', true);

        $(this.trigger).children('.autoscroll-play').addClass('hidden');
        $(this.trigger).children('.autoscroll-pause').removeClass('hidden');

    }

    stop() {

        this.cancelRefreshTimer();
        clearInterval(this.scroll.handler);
        this.body.stop(true);

        this.active = false;
        sessionStorage.removeItem('autoscrolling');

        $(this.trigger).children('.autoscroll-pause').addClass('hidden');
        $(this.trigger).children('.autoscroll-play').removeClass('hidden');

    }

    toggle() {

        this.active ? this.stop() : this.start();

    }

    registerEvents() {

        this.trigger.on('click', e => {

            this.toggle();

        });

    }

}
