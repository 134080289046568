import * as Sentry from '@sentry/browser';

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN || "",
    tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE) || 1.0,
    environment: import.meta.env.MODE || 'production',
});

import './bootstrap';
import jQuery from 'jquery';
import select2 from 'select2';
import 'tablesorter';
import { GridStack } from 'gridstack';
import Sortable from 'sortablejs';
import { evaluate } from 'mathjs';
import showdown from 'showdown';
import 'gridstack/dist/gridstack.min.css';
import 'gridstack/dist/gridstack-extra.min.css';

window.$ = jQuery;
window.jQuery = jQuery;
window.Chart = Chart;
window.GridStack = GridStack;
window.Sortable = Sortable;
window.mathEvaluate = evaluate;
window.showdown = showdown;

import {
    cl, rand, escapeHtml, updateCharLimit, removeAlerts, toMinutes, getTimeAdjustedTarget, convertRelativeDateToDate, findValueByKey
} from './functions/utilFunctions.js';

window.cl = cl;
window.rand = rand;
window.escapeHtml = escapeHtml;
window.updateCharLimit = updateCharLimit;
window.removeAlerts = removeAlerts;
window.toMinutes = toMinutes;
window.getTimeAdjustedTarget = getTimeAdjustedTarget;
window.convertRelativeDateToDate = convertRelativeDateToDate;
window.findValueByKey = findValueByKey;

import {
    Chart,
    ChartDataLabels,
    lineOptions,
    filledLineOptions,
    donutOptions,
    barOptions,
    horzBarOptions,
    stackedBarOptions,
    horzStackedBarOptions,
    barOptionsWithLegend,
    horzBarOptionsWithLegend,
    multiBarOptionsWithLegend,
    horzMultiBarOptionsWithLegend,
    stackedMultiBarOptionsWithLegend,
    horzStackedMultiBarOptionsWithLegend,
} from './classes/config/chart-config.js';

window.Chart = Chart;
window.ChartDataLabels = ChartDataLabels;
window.lineOptions = lineOptions;
window.filledLineOptions = filledLineOptions;
window.donutOptions = donutOptions;
window.barOptions = barOptions;
window.horzBarOptions = horzBarOptions;
window.stackedBarOptions = stackedBarOptions;
window.horzStackedBarOptions = horzStackedBarOptions;
window.barOptionsWithLegend = barOptionsWithLegend;
window.horzBarOptionsWithLegend = horzBarOptionsWithLegend;
window.multiBarOptionsWithLegend = multiBarOptionsWithLegend;
window.horzMultiBarOptionsWithLegend = horzMultiBarOptionsWithLegend;
window.stackedMultiBarOptionsWithLegend = stackedMultiBarOptionsWithLegend;
window.horzStackedMultiBarOptionsWithLegend = horzStackedMultiBarOptionsWithLegend;

// poet classes import
import Checklist from './classes/checklist.js'
import CustomChart from "./classes/custom-chart.js";
import CustomDashboard from "./classes/custom-dashboard.js";
import DataExport from "./classes/data-export.js";
import GlobalSearch from "./classes/global-search.js";
import MassEdit from "./classes/mass-edit.js";
import Modal from "./classes/modal.js";
import MultiSelect from "./classes/multi-select.js";
import OeeDataForm from "./classes/oee-data-form.js";
import RcaStoreForm from "./classes/rca-store-form.js";
import SignOffForm from "./classes/sign-off-form.js";
import SkuSelect from "./classes/sku-select.js";
import TdTooltip from "./classes/td-tooltip.js";
import TrHighlight from "./classes/tr-highlight.js";
import Assistant from "./classes/assistant.js";

import AddFieldset from "./classes/tools/add-fieldset.js";
import AutoRefresh from "./classes/tools/auto-refresh.js";
import Autoscroll from "./classes/tools/autoscroll.js";
import OeeTargetCalculator from "./classes/tools/oee-target-calculator.js";

import { pluginLegendPadding } from "./classes/chartjs-plugins/legend-padding.js";
import { trendline } from "./classes/chartjs-plugins/trendline.js";
import { dataLinks } from "./classes/chartjs-plugins/data-links.js";
import { colorConvert } from "./functions/colorConvert.js";

window.Checklist = Checklist;
window.CustomChart = CustomChart;
window.CustomDashboard = CustomDashboard;
window.DataExport = DataExport;
window.GlobalSearch = GlobalSearch;
window.MassEdit = MassEdit;
window.Modal = Modal;
window.MultiSelect = MultiSelect;
window.OeeDataForm = OeeDataForm;
window.RcaStoreForm = RcaStoreForm;
window.SignOffForm = SignOffForm;
window.SkuSelect = SkuSelect;
window.TdTooltip = TdTooltip;
window.TrHighlight = TrHighlight;
window.Assistant = Assistant;

window.AddFieldset = AddFieldset;
window.AutoRefresh = AutoRefresh;
window.Autoscroll = Autoscroll;
window.OeeTargetCalculator = OeeTargetCalculator;

window.pluginLegendPadding = pluginLegendPadding;
window.Chart.register(trendline);
window.dataLinks = dataLinks;
window.colorConvert = colorConvert;

select2();

window.viteReadyQueue = window.viteReadyQueue || [];

window.addEventListener('viteReady', () => {
    window.isViteReady = true;
    while (window.viteReadyQueue.length > 0) {
        const callback = window.viteReadyQueue.shift();
        callback();
    }

    // Capture any errors collected before Vite loaded
    if (window.__earlyErrors?.length) {
        window.__earlyErrors.forEach((errorEvent) => {
            Sentry.captureException(errorEvent.error, {
                extra: {
                    message: errorEvent.message,
                    file: errorEvent.source || "Unknown source",
                    line: errorEvent.lineno || "Unknown line",
                    column: errorEvent.colno || "Unknown column",
                    stack: errorEvent.error?.stack || "No stack trace"
                }
            });
        });
        window.__earlyErrors = [];
    }

    // Now capture any new errors after Vite is fully loaded
    window.addEventListener("error", (event) => {
        Sentry.captureException(event.error || new Error(event.message), {
            extra: {
                type: "error",
                message: event.message,
                file: event.filename || "Unknown source",
                line: event.lineno || "Unknown line",
                column: event.colno || "Unknown column",
                stack: event.error?.stack || "No stack trace"
            }
        });
    });

    // Capture unhandled promise rejections
    window.addEventListener("unhandledrejection", (event) => {
        Sentry.captureException(event.reason || new Error("Unhandled Promise Rejection"), {
            extra: {
                type: "unhandledrejection",
                message: event.reason?.message || "Unhandled Promise Rejection",
                stack: event.reason?.stack || "No stack trace"
            }
        });
    });
});

window.dispatchEvent(new Event('viteReady'));
