import $ from 'jquery';
export default class Modal {

    constructor() {

        this.overlay = $('#modal-overlay');
        // this.modal = 
        this.registerEvents();

    }

    hideOverlay() {

        this.overlay.removeClass('modals-visible').addClass('modals-hidden');

    }

    displayOverlay() {

        this.overlay.removeClass('modals-hidden').addClass('modals-visible');

    }

    hideModals() {

        this.hideOverlay();
        $('.modal').removeClass('modal-visible').addClass('modal-hidden');
        
    }
    
    displayModal(modal) {
        
        
        this.displayOverlay();
        $('#modal-'+modal).removeClass('modal-hidden').addClass('modal-visible');

    }
    
    hideModal(modal) {
        
        this.hideOverlay();
        $('#modal-'+modal).removeClass('modal-visible').addClass('modal-hidden');

    }

    registerEvents() {

        // click: modal trigger
        $('[data-modal]').on('click', e => {

            this.hideModals();
            this.displayModal($(e.currentTarget).data('modal'))
        
       });

        // click: modal hide
        $('[data-modal-hide]').on('click', e => {

            this.hideModals();
        
       });

    }

}
