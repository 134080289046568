export var trendline = {
    id: 'trendline',
    beforeDatasetsDraw: function(chart) {
        var supportedChartTypes = ['line', 'bar'];

        if (supportedChartTypes.includes(chart.config.type)) {
            var ctx = chart.ctx;

            chart.data.datasets.forEach(function(dataset, index) {
                let yScaleId = dataset.yAxisID;  // get y-axis ID for the current dataset
                let yScale = chart.scales[yScaleId];  // get the scale for the current dataset

                var meta = chart.getDatasetMeta(index);
                var drawTrendline = dataset.trendline && meta.visible;

                if (drawTrendline) {
                    addFitter(meta, ctx, dataset, yScale);
                }
            });
        }
    }
};


function addFitter(datasetMeta, ctx, dataset, yScale) {
    var color = dataset.trendline.color || dataset.borderColor;
    var width = dataset.trendline.width || 2;
    var style = dataset.trendline.style || 'dotted';

    color = (color !== undefined) ? color : 'rgba(169, 169, 169, .6)';

    var startIndex = null;
    var lastIndex = dataset.data.length - 1;
    var endPos = datasetMeta.data[lastIndex].x;
    var fitter = new LineFitter();

    dataset.data.forEach(function(data, index) {
        if (data !== null) {
            let fitterData = (data.y !== undefined) ? data.y : data;
            fitter.add(index, fitterData);
            if (startIndex === null) {
                startIndex = index;
            }
        }
    });

    var startPos = datasetMeta.data[startIndex].x;

    if (style === 'dotted') {
        ctx.setLineDash([2, 3]);
    }
    ctx.beginPath();
    ctx.moveTo(startPos, yScale.getPixelForValue(fitter.project(startIndex), startIndex));
    ctx.lineTo(endPos, yScale.getPixelForValue(fitter.project(lastIndex), lastIndex));
    ctx.lineWidth = width;
    ctx.strokeStyle = color;
    ctx.stroke();

    ctx.setLineDash([]);
}

function LineFitter() {
    this.count = 0;
    this.sumX = 0;
    this.sumX2 = 0;
    this.sumXY = 0;
    this.sumY = 0;
}

LineFitter.prototype = {
    add: function(x, y) {
        this.count++;
        this.sumX += x;
        this.sumX2 += x * x;
        this.sumXY += x * y;
        this.sumY += y;
    },
    project: function(x) {
        var det = this.count * this.sumX2 - this.sumX * this.sumX;
        var offset = (this.sumX2 * this.sumY - this.sumX * this.sumXY) / det;
        var scale = (this.count * this.sumXY - this.sumX * this.sumY) / det;
        return offset + x * scale;
    }
};

domReady(() => {
    $(document).on('change', '.toggle-trendlines .switch-input', function() {
        var chartName = $(this).attr('data-chart');
        var chart = window.charts[chartName];
        var datasets = chart.config.data.datasets;

        datasets.forEach(function(dataset) {
            var trendlineState = dataset.trendline.hidden || dataset.trendline;

            if (typeof dataset.trendline == "object") {
                dataset.trendline.hidden = !trendlineState;
            } else {
                dataset.trendline = !trendlineState;
            }
        });

        chart.update();
    });
});
